import React from 'react'
import AdminSidebar from '../../Comman/AdminSidebar';
import AdminHeader from '../../Comman/AdminHeader';
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Switch from "react-switch";
import "./Switch.css"
import api from '../../../Comman_Admin/axios';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
    geocodeByPlaceId
} from 'react-places-autocomplete'
import $ from 'jquery'
window.jquery = window.$ = $

function EditChemist() {

    const [chemist_shop, setchemist_shop] = useState('');
    const [contact_no, setcontact_no] = useState("");
    const [address_line1, setaddress_line1] = useState('');
    const [address_line2, setaddress_line2] = useState('');
    const [state, setstate] = useState('');
    const [city, setcity] = useState('');
    const [landmark, setlandmark] = useState('');
    const [pincode, setpincode] = useState('');
    const [cityapi, setcityapi] = useState([]);
    const [stateapi, setstateapi] = useState([]);
    const [lat, setlat] = useState("");
    const [lang, setlang] = useState('');
    const [FullAddress, setFullAddress] = useState([]);


    useEffect(() => {
        IndanStage()
    }, []);

    // useEffect(() => {
    //     Citylist()
    // }, []);


    function IndanStage(res) {
        try {
            let customToken = sessionStorage.getItem('customToken');
            if (customToken) {
                let headers = {
                    Authorization:
                        "Bearer " + customToken,
                    "Content-Type": "application/json",
                };
                api.get(`/admin/get_state`, { headers })
                    .then(res => {
                        const tableData = res.data.data;
                        setstateapi(tableData)
                    })
            }
        }
        catch (err) {
            res.status(404).json({ success: false, message: err.message })
        }
    }

    $(document).ready(function () {
        $('#select2').change(function () {
            var value = $(this).val();
            Citylist();
        });

        function Citylist(req, res) {
            try {
                var value = $('#select2').val();
                let customToken = sessionStorage.getItem('customToken');
                if (customToken) {
                    let headers = {
                        Authorization:
                            "Bearer " + customToken,
                        "Content-Type": "application/json",
                    };
                    api.get(`/employee/get_city/${value}`, { headers })
                        .then(res => {
                            const tableData = res.data.data;
                            setcityapi(tableData)
                        })
                }
            } catch (err) {
                res.status(404).json({ success: false, message: err.message })
            }

        }

    });



    const [values, setValues] = useState({
        chemist_shop: '',
        contact_no: "",
        address_line1: "",
        address_line2: "",
        state: "",
        landmark: "",
        city: "",
        pincode: "",
        lat: "",
        lng: ""
    });
    const [error, setError] = useState(null)

    const errorDiv = error
        ? <div className="text-center alert alert-danger">
            {error}
        </div>
        : '';

    const history = useHistory();
    const { id } = useParams();

    useEffect(() => {
        chemistdataget()
    }, [])

    function chemistdataget(req, res) {
        try {
            let customToken = sessionStorage.getItem('customToken');
            if (customToken) {
                let headers = {
                    Authorization:
                        "Bearer " + customToken,
                    "Content-Type": "application/json",
                };
                api.get(`/admin/get_single_chemist/${id}`, { headers })
                    .then(res => {
                        const tableData = res.data.data[0];
                        setValues({
                            chemist_shop: tableData.chemist_shop,
                            contact_no: tableData.contact_no,
                            address_line1: tableData.address_line1,
                            address_line2: tableData.address_line2,
                            state: tableData.state,
                            landmark: tableData.landmark,
                            city: tableData.city,
                            pincode: tableData.pincode,
                            lat: tableData.lat,
                            lang: tableData.lang

                        })
                        setchemist_shop(tableData.chemist_shop);
                        setcontact_no(tableData.contact_no);
                        setaddress_line1(tableData.address_line1);
                        setaddress_line2(tableData.address_line2);
                        setstate(tableData.state);
                        setlandmark(tableData.landmark);
                        setcity(tableData.city);
                        setpincode(tableData.pincode);
                        setlat(tableData.lat);
                        setlang(tableData.lang);

                    })
            }
        } catch (err) {
            res.status(404).json({ success: false, message: err.message })
        }
    }

    function handleSubmit(event) {
        event.preventDefault()
        setError(null);

        let item = {
            chemist_id: id,
            chemist_shop: chemist_shop,
            contact_no: contact_no,
            address_line1: address_line1,
            address_line2: address_line2,
            state: state,
            landmark: landmark,
            city: city,
            pincode: pincode,
            lat: String(lat),
            lang: String(lang)
        }
        console.log(item)
        let customToken = sessionStorage.getItem('customToken');
        if (customToken) {
            let headers = {
                Authorization:
                    "Bearer " + customToken,
                "Content-Type": "application/json",
            };
            api.post(`/admin/update_chemist`, item, { headers }).then((res) => {
                history.push('/admin/chemist')
                // window.location.reload(true)
            }).catch(err => {
                if (err.response) {
                    setError(err.response.data.error.message)
                }
                else if (err.request) {
                    setError(err.request);
                }
                else {
                    setError(err.message);
                }
            })
        }
        console.log(item)
    }

    const handleSelect = async value => {
        const result = await geocodeByAddress(value);
        console.log(result[0].address_components);
        if (result[0].address_components !== undefined) {
            let addrComp = result[0].address_components;
            for (let i = 0; i < addrComp.length; i++) {
                var typ = addrComp[i].types;
                for (let j = 0; j < typ.length; j++) {
                    if (typ[j] === 'administrative_area_level_1') {
                        var state = addrComp[i].long_name; //store the state
                    }
                    else if (typ[j] === 'locality') {
                        var city = addrComp[i].long_name; //store the city
                    }
                    // else if (typ[j] === 'country') {
                    //     var country = addrComp[i].long_name; //store the country        
                    // }
                    else if (typ[j] === 'postal_code') {
                        var postal_code = addrComp[i].long_name
                    }
                    else { }

                    if (state != null && city != null && postal_code != null) {
                        setpincode(postal_code);
                        setstate(state);
                        setcity(city);
                        StateCityApi();
                        break;
                    }
                }
            }
            // console.log(value);
            //console.log(result)
        }

        function StateCityApi() {
            let item = {
                state_name: state,
                city_name: city
            }

            api.post(`/employee/add_state_city`, item)
                .then(res => {
                    // history.push('/mr/chemist')
                    const tableData = res.data.data
                    setstate(tableData.state_id);
                    setcity(tableData.city_id);

                    var sthtml = '';
                    var cthtml = '';
                    var statedata = [];
                    var ctatedata = [];
                    api.get(`/employee/get_state`)
                        .then(res => {
                            statedata = res.data.data;
                            for (let ij = 0; ij < statedata.length; ij++) {

                                if (statedata[ij].id === tableData.state_id) {
                                    sthtml += '<option value="' + statedata[ij].id + '" selected>' + statedata[ij].name + '</option>';
                                }
                                else {
                                    sthtml += '<option value="' + statedata[ij].id + '">' + statedata[ij].name + '</option>';
                                }
                            }
                            $('#select2').html(sthtml);
                        })

                    api.get(`/employee/get_city/${tableData.state_id}`)
                        .then(res => {
                            ctatedata = res.data.data;
                            for (let ip = 0; ip < ctatedata.length; ip++) {

                                if (ctatedata[ip].id === tableData.city_id) {
                                    cthtml += '<option value="' + ctatedata[ip].id + '" selected>' + ctatedata[ip].city_name + '</option>';
                                }
                                else {
                                    cthtml += '<option value="' + ctatedata[ip].id + '">' + ctatedata[ip].city_name + '</option>';
                                }
                            }
                            $('#citys').html(cthtml);
                        })


                }).catch(err => {
                    if (err.response) {
                        setError(err.response.data.error.message)
                    }
                    else if (err.request) {
                        setError(err.request);
                    }
                    else {
                        setError(err.message);
                    }
                })
        }



        const ll = await getLatLng(result[0])

        const sp = value.split(',');
        let area = "";
        for (let i = 1; i < sp.length; i++) {
            area += sp[i];
        }
        setaddress_line1(sp[0]);
        setFullAddress(value);
        setaddress_line2(area);
        // setcoordinates(ll);
        setlat(ll.lat);
        setlang(ll.lng);
    }

    return (
        <React.Fragment>
            <AdminHeader />
            <div className="main-container" id="container">
                <AdminSidebar />
                <div id="content" className="main-content">
                    <div className="container layout-spacing layout-top-spacing center">
                        <div className="row">
                            <div id="flRegistrationForm" className="col-lg-12 layout-spacing">
                                <div className="statbox widget box box-shadow">
                                    <div className="widget-content widget-content-area">
                                        <div className="row col-xl-12 col-md-12 col-sm-12 col-12 text-left">
                                            <h4><strong>Chemist Details</strong></h4>
                                        </div>
                                        <hr />
                                        <form onSubmit={handleSubmit} >
                                            <div className='row'>
                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Chemist Name</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        placeholder="Enter Name"
                                                        value={chemist_shop}
                                                        required
                                                        onChange={(e) => setchemist_shop(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Contact Number</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control filterme"
                                                        min="10" max="10" maxLength="10"
                                                        id="contact_no"
                                                        placeholder="Enter Contact Number"
                                                        value={contact_no}
                                                        required
                                                        onChange={(e) => setcontact_no(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group mb-0 col-md-12">
                                                    <label><strong style={{ color: "black" }}>Location Search</strong></label>
                                                    <PlacesAutocomplete
                                                        className="form-control"
                                                        value={FullAddress}
                                                        onChange={setFullAddress}
                                                        onSelect={handleSelect}
                                                    >
                                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                            <div className="form-group mb-4">
                                                                <input
                                                                    {...getInputProps({
                                                                        placeholder: 'Search Places ...',
                                                                        className: 'location-search-input form-control',
                                                                    })}
                                                                />
                                                                <div className="autocomplete-dropdown-container">
                                                                    {loading && <div>Loading...</div>}
                                                                    {suggestions.map(suggestion => {
                                                                        const className = suggestion.active
                                                                            ? 'suggestion-item--active'
                                                                            : 'suggestion-item';
                                                                        const style = suggestion.active
                                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                                        return (
                                                                            <div
                                                                                {...getSuggestionItemProps(suggestion, {
                                                                                    className,
                                                                                    style,
                                                                                })}
                                                                            >
                                                                                <span>{suggestion.description}</span>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </PlacesAutocomplete>
                                                </div>
                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Address Line 1 </strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="address_line1"
                                                        placeholder="Enter Address Line 1"
                                                        value={address_line1}
                                                        required
                                                        onChange={(e) => setaddress_line1(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Address Line 2</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="address_line2"
                                                        placeholder="Enter Address Line 2"
                                                        value={address_line2}
                                                        required
                                                        onChange={(e) => setaddress_line2(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Latitude</strong></label>
                                                    <input
                                                        name="lat" id="lat"
                                                        type="text"

                                                        className="form-control"
                                                        placeholder="Enter Latitude"
                                                        value={lat}
                                                    // onChange={(e) => setlat(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Longitude</strong></label>
                                                    <input
                                                        name="lang" id="lang"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Longitude"
                                                        value={lang}
                                                    // onChange={(e) => setlang(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>State</strong></label>
                                                    <select className="form-control" id="select2" required onChange={(e) => setstate(e.target.value)}  >
                                                        <option value="0">Select State</option>
                                                        {
                                                            stateapi &&
                                                                stateapi !== undefined ?
                                                                stateapi.map((ctr, index) => {
                                                                    return (
                                                                        <option key={index} value={ctr.id}>{ctr.name}</option>
                                                                    )
                                                                })
                                                                : "No State"

                                                        }
                                                    </select>
                                                </div>

                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "#000000" }}>City</strong></label>
                                                    <select className="form-control" id="citys" onChange={(e) => setcity(e.target.value)} >
                                                        <option value='' selected >Select City</option>
                                                        {cityapi && cityapi !== undefined ?
                                                            cityapi.map((maincity, index) => {
                                                                return (
                                                                    <option
                                                                        value={maincity.id} key={index}
                                                                    >{maincity.city_name}</option>
                                                                )
                                                            }) : "No City"}
                                                    </select>
                                                </div>


                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Landmark</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="landmark"
                                                        placeholder="Enter Landmark"
                                                        value={landmark}

                                                        onChange={(e) => setlandmark(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Pincode</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control filterme"
                                                        min="6" max="6" maxLength="6"
                                                        id="pincode"
                                                        placeholder="Enter Pincode"
                                                        value={pincode}
                                                        onChange={(e) => setpincode(e.target.value)}
                                                    />
                                                </div>


                                                <div className='col-md-12'> {errorDiv} <hr /> </div>

                                                <div className="form-group form-check pl-0 mt-5 text-center col-md-12">
                                                    <button type="submit" className="btn btn-primary mx-2">
                                                        Submit
                                                    </button>
                                                    <button type="submit" className="btn btn-info mx-2 " onClick={() => history.goBack()}>
                                                        Go Back
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EditChemist