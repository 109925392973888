import React from 'react'
import { useState, useEffect, useMemo } from 'react'
import NavBarWar from '../Comman/NavBarWar'
import api from '../Comman_Admin/axios';
import { GoogleComponent } from 'react-google-location'
import ReactPaginate from 'react-paginate';
import Footer from '../Comman/Footer';
import $ from 'jquery'
window.jquery = window.$ = $




export default function BookAnAppointment() {

    const [user, setuser] = useState([]);
    const [user1, setuser1] = useState('');
    const [city, setcity] = useState("");
    const [name, setname] = useState("");
    const [pincode, setpincode] = useState("");
    const [error, setError] = useState(null);
    const [lat, setlat] = useState("");
    const [lng, setlang] = useState('');
    const [page, setpage] = useState(0);
    const errorDiv = error
        ? <div className="text-center alert alert-danger">
            {error}
        </div>
        : '';

    const [cityapi, setcityapi] = useState([]);


    useEffect(() => {
        Citylist()
    }, []);

    function refreshPage() {
        window.location.reload(false);
    }

    function Citylist(req, res) {
        try {
            api.get(`/get_city`)
                .then(res => {
                    const tableData = res.data.data;
                    setcityapi(tableData)
                })
        } catch (err) {
            res.status(404).json({ success: false, message: err.message })
        }

    }


    function locationFilter(event) {
        event.preventDefault()
        setError(null);

        $('#displaydata').html('');
        $('#lodebutton').hide();

        const lat = sessionStorage.getItem('Latitude');
        const lng = sessionStorage.getItem('Longitude');

        var pages = 0;

        let item = {
            city: city,
            pincode: pincode,
            name: name,
            lat: lat,
            lng: lng,
            page: pages
        }

        api.post(`/get_doctor`, item)
            .then(res => {
                const tableData = res.data.data;
                const tableData1 = res.data.page;

                var html = '';

                if (tableData.length === 0 && pages === 0) {
                    $('#displaydata').html('');
                    $('#lodebutton').hide();
                    $('#nofound').show();
                    $('#searchdata').hide();

                }
                else {
                    for (var i = 0; i < tableData.length; i++) {
                        if (tableData[i].distance = '') {
                            var dist = '';
                        }
                        else {
                            var dist = tableData[i].distance;
                        }


                        html += '<div class ="content p-4 card" >';
                        html += '<div class="card-body text-center" >';
                        html += '<div class="card-body">';
                        html += '<div class="user-info">';
                        html += '<div class="row">';
                        html += '<h5 class="card-user_name text-left col-sm-6"><b>' + tableData[i].clinic_name + '</b></h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6">' + tableData[i].address_line1 + '</h5>';
                        html += '<h5 class="card-user_name text-left col-sm-6">' + tableData[i].dr_name + '</h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6">' + tableData[i].address_line2 + '</h5>';
                        html += '<h5 class="card-user_name text-left col-sm-6">' + tableData[i].speciality + '</h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6">' + tableData[i].city_name + ' &nbsp;&nbsp;' + tableData[i].state_name + ' &nbsp;&nbsp;' + tableData[i].pincode + ' </h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6"></h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6">' + dist + '</h5>';
                        html += '</div><hr />';
                        html += '<div class="row">';
                        html += '<butoon type="button" class="btn btn-info col-sm-6" value="' + tableData[i].contact_no + '"><strong>Contact No : ' + tableData[i].contact_no + '</strong></butoon>';
                        html += '<button type="button" class="btn btn-success col-sm-6" value="' + tableData[i].contact_no_for_appointment + '"><strong>Contact No For Appointment : ' + tableData[i].contact_no_for_appointment + '</strong></button>';
                        html += '</div></div></div></div></ ></div >';

                    }

                    if (tableData.length < 10) {
                        $('#lodebutton').hide();
                    }
                    else {
                        $('#lodebutton').show();
                    }

                    $('#displaydata').append(html);
                    $('#pageno').val(tableData1);
                    $('#nofound').hide();
                    $('#searchdata').hide();
                }

            }).catch(err => {

                if (err.response) {
                    setError(err.response.data.message)
                }
                else if (err.request) {
                    setError(err.request);
                }
                else {
                    setError(err.message);
                }
            })

    }




    const getMyLocation = (e) => {
        let location = null;
        let latitude = null;
        let longitude = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation
        }
        if (location) {
            location.getCurrentPosition(function (position) {
                latitude = position.coords.latitude;
                longitude = position.coords.longitude;
                setlat(latitude);
                setlang(longitude);
            })
        }
    }


    function loadMore() {
        setError(null);
        $('.ajax-load1').show();

        const lat = sessionStorage.getItem('Latitude');
        const lng = sessionStorage.getItem('Longitude');

        var pages = $('#pageno').val();


        let item = {
            city: city,
            pincode: pincode,
            name: name,
            lat: lat,
            lng: lng,
            page: pages
        }

        api.post(`/get_doctor`, item)
            .then(res => {
                const tableData = res.data.data;
                const tableData1 = res.data.page;

                var html = '';
                if (tableData.length === 0 && pages === 0) {
                    $('.ajax-load1').hide();
                    $('#displaydata').html('');
                    $('#lodebutton').hide();
                    $('#nofound').show();
                    $('#searchdata').hide();

                }
                else {
                    $('.ajax-load1').hide();

                    for (var i = 0; i < tableData.length; i++) {

                        if (tableData[i].distance = '') {
                            var dist = '';
                        }
                        else {
                            var dist = tableData[i].distance;
                        }
                        html += '<div class ="content p-4 card" >';
                        html += '<div class="card-body text-center" >';
                        html += '<div class="card-body">';
                        html += '<div class="user-info">';
                        html += '<div class="row">';
                        html += '<h5 class="card-user_name text-left col-sm-6"><b>' + tableData[i].clinic_name + '</b></h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6">' + tableData[i].address_line1 + '</h5>';
                        html += '<h5 class="card-user_name text-left col-sm-6">' + tableData[i].dr_name + '</h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6">' + tableData[i].address_line2 + '</h5>';
                        html += '<h5 class="card-user_name text-left col-sm-6">' + tableData[i].speciality + '</h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6">' + tableData[i].city_name + ' &nbsp;&nbsp;' + tableData[i].state_name + ' &nbsp;&nbsp;' + tableData[i].pincode + ' </h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6"></h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6">' + dist + '</h5>';
                        html += '</div><hr />';
                        html += '<div class="row">';
                        html += '<butoon type="button" class="btn btn-info col-sm-6" value="' + tableData[i].contact_no + '"><strong>Contact No : ' + tableData[i].contact_no + '</strong></butoon>';
                        html += '<button type="button" class="btn btn-success col-sm-6" value="' + tableData[i].contact_no_for_appointment + '"><strong>Contact No For Appointment : ' + tableData[i].contact_no_for_appointment + '</strong></button>';
                        html += '</div></div></div></div></ ></div >';

                    }

                    if (tableData.length < 10) {
                        $('#lodebutton').hide();
                    }
                    else {
                        $('#lodebutton').show();
                    }

                    $('#displaydata').append(html);
                    $('#pageno').val(tableData1);
                    $('#nofound').hide();
                    $('#searchdata').hide();
                }

            }).catch(err => {

                $('.ajax-load1').hide();

                if (err.response) {
                    setError(err.response.data.message)
                }
                else if (err.request) {
                    setError(err.request);
                }
                else {
                    setError(err.message);
                }
            })
    }





    return (
        <React.Fragment>
            <NavBarWar />
            <br />
            <br />
            <br />
            <br />


            <section className="aboutbph py-5" id="aboutbph" style={{ background: "lightgray" }}>
                <div className="container">
                    <div className="sectiontitle text-center">
                        <h1>Book An Appointment</h1>
                    </div>
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-12 m-2">
                                <div className="content p-4" class="card" style={{ background: "white" }}>
                                    <div className="col-sm-12 card-body text-center" >
                                        <div className="row card-title">
                                            {/* <h1>Book An Appointment</h1> */}
                                        </div>
                                    </div>

                                    <div className='col-md-12'>
                                        <form onSubmit={locationFilter}>
                                            <div className='row'>
                                                <div className="col-md-4">
                                                    <div className="form-group mb-0">
                                                        <label style={{ color: "#000000" }}>City</label>
                                                        <select className="form-control" id="citys" onChange={(e) => setcity(e.target.value)} >
                                                            <option value='' selected >Select City</option>
                                                            {cityapi && cityapi !== undefined ?
                                                                cityapi.map((maincity, index) => {
                                                                    return (
                                                                        <option
                                                                            value={maincity.id} key={index}
                                                                        >{maincity.city_name}</option>
                                                                    )
                                                                }) : "No City"}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group mb-0">
                                                        <label style={{ color: "#000000" }}>Pincode</label>
                                                        <input
                                                            className='form-control'
                                                            placeholder="Enter Pincode"
                                                            value={pincode}
                                                            type="text"
                                                            maxlength="6" min="6" max="6"
                                                            onChange={(e) => setpincode(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group mb-0">
                                                        <label style={{ color: "#000000" }}>Geolocation <i className="fa fa-map-marker mx-1" style={{ fontsize: "36px", color: "red" }}></i></label>
                                                        <button type="button" className="form-control" onClick={getMyLocation}>Get Geolocation</button>
                                                    </div>
                                                </div>


                                                <div className="form-group mb-4">
                                                    <input
                                                        name="lat" id="lat"
                                                        type="hidden"
                                                        className="form-control"
                                                        placeholder="Enter Lat"
                                                        value={lat}
                                                    // onChange={(e) => setlat(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group mb-4">
                                                    <input
                                                        name="lang" id="lang"
                                                        type="hidden"
                                                        className="form-control"
                                                        placeholder="Emnter Lung"
                                                        value={lng}
                                                    // onChange={(e) => setlang(e.target.value)}
                                                    />
                                                </div>

                                                <div className='col-md-12'> {errorDiv} <hr /> </div>


                                                <div className="col-md-12">
                                                    <br />
                                                    <div className='text-center'>
                                                        <button className="btn btn-primary  mx-2" type='submit'> Search</button>
                                                        <button className="btn btn-info  mx-2" type="button" onClick={refreshPage}> Reset </button>
                                                    </div>
                                                    <br /><hr /><br />
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div id="displaydata" className='col-md-12'> </div>
                                    <div id="nofound" className='col-md-12 text-center' style={{ display: 'none' }} ><h5>No Any Data Found</h5></div>
                                    <div id="searchdata" className='col-md-12 text-center' style={{ display: 'block' }}><h5>  Please Search </h5></div>
                                    <div className="col-md-12">
                                        <div className='text-center'>
                                            <div className="ajax-load1 text-center" style={{ display: 'none' }}>
                                                <img src="assets/loader.gif" />
                                            </div>
                                            <input type="hidden" id="pageno" value='0' />
                                            <div id="lodebutton" style={{ display: 'none' }}>
                                                <button onClick={loadMore} className="btn btn-info">LoadMore</button>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </section >

            <Footer />
        </React.Fragment >
    )
}
