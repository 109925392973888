import React from 'react'
import "./nav.css"

import $ from 'jquery'
import { Link } from 'react-router-dom';
window.jquery = window.$ = $




export default function NavBarWar() {

    $(document).ready(function () {
        $('#nav').click(function () {
            $('#togall').show();
        });
        setTimeout(function () {
            $('#togall').fadeOut('fast');
        }, 5000);
    });



    var pathname = window.location.pathname;
    var pathnamesplits = pathname.split('/');
    var url = pathnamesplits[1];



    return (
        <React.Fragment>
            <header>
                <div className="container">
                    <nav className="navbar navbar-expand-md navbar-dark fixed-top" style={{backgroundColor: "#222"}}>
                        <Link to="/">
                            <img src="assets/images/logo.png" style={{ width: '30%' }} />
                        </Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            id="nav"
                            data-toggle="collapse"
                        // data-target="#collapsibleNavbar"
                        >
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse" id="togall"
                            style={{
                                display: "none",
                            }}>
                            <ul className="navbar-nav ml-auto">
                                {/* <li>
                                    <a
                                        href={(url === "chemistlocator" || url === "specialistlocator" || url === "testbph" ?
                                            "/" : "#aboutbph"
                                        )}>
                                        <span className="deskdisplay">
                                            Know more
                                            <br />
                                            about BPH
                                        </span>
                                        <span className="mobdisplay">Know more about BPH</span>
                                    </a>
                                </li>
                                <li>
                                    <a href={(url === "chemistlocator" || url === "specialistlocator" ?
                                        "testbph" : "#testbph"
                                    )} >

                                        <span className="deskdisplay">
                                            Test your BPH
                                            <br />
                                            Severity
                                        </span>
                                        <span className="mobdisplay">Test your BPH Severity </span>
                                    </a>
                                </li> */}
                                <li>
                                    <Link to="/chemistlocator">
                                        <span className="deskdisplay">
                                            Chemist
                                            <br />
                                            Locator
                                        </span>
                                        <span className="mobdisplay">Chemist Locator</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/" >
                                        <span className="deskdisplay">
                                            Specialist Locator
                                            <br />
                                            and Book Appointment
                                        </span>
                                        <span className="mobdisplay">Specialist Locator</span>
                                    </Link>
                                </li>
                                {/* <li>
                                    <a data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <span className="deskdisplay">

                                            Washroom
                                            <br />
                                            Locator
                                        </span>
                                        <span className="mobdisplay"> Washroom Locator</span>
                                    </a>
                                </li> */}
                                {/* <li>
                                    <a data-toggle="modal" data-target="#myModal">
                                        <span className="deskdisplay">
                                            Dose
                                            <br />
                                            Reminder
                                        </span>
                                        <span className="mobdisplay">Dose Reminder</span>
                                    </a>
                                </li> */}
                                {/* <li>
                                    <a href='/bookanappointment'>
                                        <span className="deskdisplay">
                                            Book an
                                            <br />
                                            Appointment
                                        </span>
                                        <span className="mobdisplay">Book an Appointment</span>
                                    </a>
                                </li> */}
                                {/* <li>
                                    <a
                                        data-toggle="collapse"
                                        data-target=".navbar-collapse.show"
                                        style={{ cursor: "pointer" }}
                                    >
                                        <span className="deskdisplay">
                                            Your Relief
                                            <br />
                                            Hub
                                        </span>
                                        <span className="mobdisplay">Your Relief Hub</span>
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>

        </React.Fragment>
    )
}
