import React from 'react'
import { useState, useEffect, useMemo } from 'react'
import NavBarWar from '../Comman/NavBarWar'
import api from '../Comman_Admin/axios';
import { GoogleComponent } from 'react-google-location'
import ReactPaginate from 'react-paginate';
import Footer from '../Comman/Footer';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
    geocodeByPlaceId
} from 'react-places-autocomplete'
import Select from 'react-select';
import $ from 'jquery'
window.jquery = window.$ = $



export default function SpecialistLocation() {

    const [user, setuser] = useState([]);
    const [user1, setuser1] = useState('');
    const [city, setcity] = useState("");
    const [name, setname] = useState("");
    const [pincode, setpincode] = useState("");
    const [error, setError] = useState(null);
    const [lat, setlat] = useState("");
    const [state, setstate] = useState("");
    const [lng, setlang] = useState('');
    const [page, setpage] = useState(0);
    const errorDiv = error
        ? <div className="text-center alert alert-danger">
            {error}
        </div>
        : '';

    const [cityapi, setcityapi] = useState([]);
    const [stateapi, setstateapi] = useState([]);



    useEffect(() => {
        IndanStage()
    }, []);

    function refreshPage() {
        window.location.reload(false);
    }


    function IndanStage(res) {
        try {
            api.get(`/employee/get_state`)
                .then(res => {
                    // const tableData = res.data.data;
                    // console.log(tableData)
                    // setstateapi(tableData)
                    const options = res.data.data.map(item => ({
                        label: item.name,
                        value: item.id,
                    }))
                    setstateapi(options)
                })
        }
        catch (err) {
            res.status(404).json({ success: false, message: err.message })
        }
    }

    var krunal = state ? state : ""

    useEffect(() => {
        Citylist()
    }, [krunal]);


    function Citylist(req, res) {
        try {
            api.get(`/employee/get_city/${krunal}`)
                .then(res => {
                    // const tableData = res.data.data;
                    // setcityapi(tableData)
                    const options = res.data.data.map(maincity => ({
                        label: maincity.city_name,
                        value: maincity.id,
                    }))
                    setcityapi(options)
                })
        } catch (err) {
            res.status(404).json({ success: false, message: err.message })
        }
    }





    function locationFilter(event) {
        event.preventDefault()
        setError(null);

        $('#displaydata').html('');
        $('#lodebutton').hide();

        const lat = sessionStorage.getItem('Latitude');
        const lng = sessionStorage.getItem('Longitude');

        var pages = 0;

        let item = {
            city: city,
            pincode: pincode,
            name: name,
            state: state,
            lat: String(lat),
            lang: String(lng),
            page: pages
        }
        console.log(item)
        api.post(`/get_doctor`, item)
            .then(res => {
                const tableData = res.data.data;
                const tableData1 = res.data.page;

                var html = '';

                if (tableData.length === 0 && pages === 0) {
                    $('#displaydata').html('');
                    $('#lodebutton').hide();
                    $('#nofound').show();
                    $('#searchdata').hide();

                }
                else {
                    for (var i = 0; i < tableData.length; i++) {


                        if (tableData[i].distance === '' || tableData[i].distance === null || tableData[i].distance === undefined) {
                            var dist1 = 'Distance : 0 &nbsp;Km';;
                        }
                        else {
                            var dist = tableData[i].distance;
                            // console.log(dist)
                            var dist1 = 'Distance : ' + dist.toLocaleString(tableData[i].distance, { maximumFractionDigits: 2 }) + '&nbsp;Km';

                        }

                        html += '<div class="content p-4 card" >';
                        html += '<div class="card-body text-center" >';
                        html += '<div class="card-body">';
                        html += '<div class="user-info">';
                        html += '<div class="row">';
                        html += '<h5 class="card-user_name text-left col-sm-6"><b>' + tableData[i].dr_name + '</b></h5>';
                        html += '<h5 class="card-user_name text-left col-sm-6"><b>Hospital Name :  ' + tableData[i].clinic_name + '</b></h5>';
                        html += '<h5 class="card-user_name text-left col-sm-6">' + tableData[i].speciality + '</h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6"></h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6">Address :   ' + tableData[i].address_line1 + '</h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6"></h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6">' + tableData[i].address_line2 + '</h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6"></h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6">' + tableData[i].city_name + ' &nbsp;' + tableData[i].state_name + ' &nbsp;' + tableData[i].pincode + ' </h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6">' + dist1 + '</h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6"></h5>';
                        html += '</div><hr />';
                        html += '<div class="row">';
                        html += '<div class="col-md-12">';
                        html += '<a href="tel:' + tableData[i].contact_no + '" class="btn btn-info col-sm-5 my-3" value="' + tableData[i].contact_no + '"><strong>Contact No : ' + tableData[i].contact_no + '</strong></a>';
                        html += '<a class="col-sm-2"></a>';
                        html += '<a href="tel: ' + tableData[i].contact_no_for_appointment + '" class="btn btn-success col-sm-5" value="' + tableData[i].contact_no_for_appointment + '"><strong>Contact No For Appointment : ' + tableData[i].contact_no_for_appointment + '</strong></a>';
                        html += '</div></div></div></div></ ></div ></div >';

                    }
                    if (tableData.length < 10) {
                        $('#lodebutton').hide();
                    }
                    else {
                        $('#lodebutton').show();
                    }
                    $('#displaydata').append(html);
                    $('#pageno').val(tableData1);
                    $('#nofound').hide();
                    $('#searchdata').hide();
                }
            }).catch(err => {

                if (err.response) {
                    setError(err.response.data.message)
                }
                else if (err.request) {
                    setError(err.request);
                }
                else {
                    setError(err.message);
                }
            })
    }




    const getMyLocation = (e) => {

        $(document).ready(function () {
            Citylist();
        });

        function Citylist(req, res) {
            try {
                api.get(`/employee/get_city/${krunal}`)
                    .then(res => {
                        // const tableData = res.data.data;
                        // setcityapi(tableData)
                        const options = res.data.data.map(maincity => ({
                            label: maincity.city_name,
                            value: maincity.id,
                        }))
                        setcityapi(options)
                    })
            } catch (err) {
                res.status(404).json({ success: false, message: err.message })
            }
        }

        let location = null;
        let lat = null;
        let lng = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation
        }
        if (location) {
            location.getCurrentPosition(function (position) {
                lat = position.coords.latitude;
                lng = position.coords.longitude;
                setlat(lat);
                setlang(lng);
                getAddress();

            })
            const getAddress = () => {
                const googleKey = "AIzaSyDj-wLttjp7i7HG0QnRRQccEBDmllpt0E8";
                fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleKey}`)
                    .then(res => res.json())
                    .then(address =>
                        setZip(address)
                        // console.log(address),
                    )
            }

            const setZip = (address) => {
                // console.log(address);
                // console.log(address.results);

                const result = address.results
                if (result[0].address_components !== undefined) {
                    let addrComp = result[0].address_components;
                    for (let i = 0; i < addrComp.length; i++) {
                        var typ = addrComp[i].types;
                        for (let j = 0; j < typ.length; j++) {
                            if (typ[j] === 'administrative_area_level_1') {
                                var state = addrComp[i].long_name; //store the state
                            }
                            else if (typ[j] === 'locality') {
                                var city = addrComp[i].long_name; //store the city
                            }
                            else if (typ[j] === 'postal_code') {
                                var postal_code = addrComp[i].long_name
                            }

                            else { }

                            if (state != null && city != null && postal_code != null) {
                                setpincode(postal_code);
                                $('#Pincode').attr('readonly', true);
                                setstate(state);
                                setcity(city);
                                //StateCityApi();

                                break;
                            }
                        }
                    }
                }

                let item = {
                    state_name: state,
                    city_name: city,
                    pincode: postal_code
                }
                console.log(item)

                api.post(`/employee/add_state_city`, item)
                    .then(res => {
                        // history.push('/mr/chemist')
                        const tableData = res.data.data

                        setstate(tableData.state_id);
                        setcity(tableData.city_id);
                        var sthtml = '';
                        var cthtml = '';
                        var statedata = [];
                        var ctatedata = [];
                        api.get(`/employee/get_state`)
                            .then(res => {
                                statedata = res.data.data;
                                for (let ij = 0; ij < statedata.length; ij++) {

                                    if (statedata[ij].id === tableData.state_id) {
                                        sthtml += '<option   value="' + statedata[ij].id + '" selected>' + statedata[ij].name + '</option>';
                                    }
                                    else {
                                        // sthtml += '<option value="' + statedata[ij].id + '">' + statedata[ij].name + '</option>';
                                    }
                                }
                                $('#select2').html(sthtml);
                            })

                        api.get(`/employee/get_city/${tableData.state_id}`)
                            .then(res => {
                                ctatedata = res.data.data;
                                for (let ip = 0; ip < ctatedata.length; ip++) {

                                    if (ctatedata[ip].id === tableData.city_id) {
                                        cthtml += '<option value="' + ctatedata[ip].id + '" selected>' + ctatedata[ip].city_name + '</option>';
                                    }
                                    else {
                                        // cthtml += '<option value="' + ctatedata[ip].id + '">' + ctatedata[ip].city_name + '</option>';
                                    }
                                }
                                $('#citys').html(cthtml);
                            })

                    }).catch(err => {
                        if (err.response) {
                            setError(err.response.data.error.message)
                        }
                        else if (err.request) {
                            setError(err.request);
                        }
                        else {
                            setError(err.message);
                        }
                    })

            }

        }


    }




    function loadMore() {
        setError(null);
        $('.ajax-load1').show();

        const lat = sessionStorage.getItem('Latitude');
        const lng = sessionStorage.getItem('Longitude');

        var pages = $('#pageno').val();


        let item = {
            city: city,
            pincode: pincode,
            name: name,
            state: state,
            lat: String(lat),
            lang: String(lng),
            page: pages
        }

        api.post(`/get_doctor`, item)
            .then(res => {
                const tableData = res.data.data;
                const tableData1 = res.data.page;
                console.log(tableData1);



                var html = '';

                if (tableData.length === 0 && pages === 0) {
                    $('.ajax-load1').hide();
                    $('#displaydata').html('');
                    $('#lodebutton').hide();
                    $('#nofound').show();
                    $('#searchdata').hide();

                }
                else {
                    $('.ajax-load1').hide();

                    for (var i = 0; i < tableData.length; i++) {


                        if (tableData[i].distance === '' || tableData[i].distance === null || tableData[i].distance === undefined) {
                            var dist1 = 'Distance : 0 &nbsp;Km';;
                        }
                        else {
                            var dist = tableData[i].distance;
                            // console.log(dist)
                            var dist1 = 'Distance : ' + dist.toLocaleString(tableData[i].distance, { maximumFractionDigits: 2 }) + '&nbsp;Km';

                        }



                        html += '<div class ="content p-4 card" >';
                        html += '<div class="card-body text-center" >';
                        html += '<div class="card-body">';
                        html += '<div class="user-info">';
                        html += '<div class="row">';
                        html += '<h5 class="card-user_name text-left col-sm-6"><b>' + tableData[i].dr_name + '</b></h5>';
                        html += '<h5 class="card-user_name text-left col-sm-6"><b>Hospital Name :  ' + tableData[i].clinic_name + '</b></h5>';
                        html += '<h5 class="card-user_name text-left col-sm-6">' + tableData[i].speciality + '</h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6"></h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6">Address :   ' + tableData[i].address_line1 + '</h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6"></h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6">' + tableData[i].address_line2 + '</h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6"></h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6">' + tableData[i].city_name + ' &nbsp;' + tableData[i].state_name + ' &nbsp;' + tableData[i].pincode + ' </h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6">' + dist1 + '</h5>';
                        html += '<h5 class="card-user_occupation text-left col-sm-6"></h5>';
                        html += '</div><hr />';
                        html += '<div class="row">';
                        html += '<div class="col-md-12">';
                        html += '<a href="tel:' + tableData[i].contact_no + '" class="btn btn-info col-sm-5 my-3" value="' + tableData[i].contact_no + '"><strong>Contact No : ' + tableData[i].contact_no + '</strong></a>';
                        html += '<a class="col-sm-2"></a>';
                        html += '<a href="tel: ' + tableData[i].contact_no_for_appointment + '" class="btn btn-success col-sm-5" value="' + tableData[i].contact_no_for_appointment + '"><strong>Contact No For Appointment : ' + tableData[i].contact_no_for_appointment + '</strong></a>';
                        html += '</div></div></div></div></ ></div ></div >';


                    }

                    if (tableData.length < 10) {
                        $('#lodebutton').hide();
                    }
                    else {
                        $('#lodebutton').show();
                    }

                    $('#displaydata').append(html);
                    $('#pageno').val(tableData1);
                    $('#nofound').hide();
                    $('#searchdata').hide();
                }

            }).catch(err => {

                $('.ajax-load1').hide();

                if (err.response) {
                    setError(err.response.data.message)
                }
                else if (err.request) {
                    setError(err.request);
                }
                else {
                    setError(err.message);
                }
            })
    }





    return (
        <React.Fragment>
            <NavBarWar />
            <br />
            <br />
            <br />
            <br />


            <section className="aboutbph py-5" id="aboutbph" style={{ background: "lightgray" }}>
                <div className="container">
                    <div className="sectiontitle text-center">
                        <h1>Specialist Location</h1>
                    </div>
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-12 m-2">
                                <div className="content p-4" class="card" style={{ background: "white" }}>
                                    <div className="col-sm-12 card-body text-center" >
                                        <div className="row card-title">
                                            {/* <h1>Book An Appointment</h1> */}
                                        </div>
                                    </div>

                                    <div className='col-md-12'>
                                        <form onSubmit={locationFilter}>
                                            <div className='row'>

                                                <div className="col-md-4">
                                                    <div className="form-group mb-0">
                                                        <label><strong style={{ color: "black" }}>State</strong></label>
                                                        <Select
                                                            id="select2"
                                                            className='search-line'
                                                            placeholder='Search...'
                                                            // options={options}
                                                            options={stateapi.map((sponsor) =>
                                                            ({
                                                                label: sponsor.label,
                                                                value: sponsor.value
                                                            })
                                                            )}
                                                            onChange={opt => setstate(opt.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group mb-0">
                                                        <label><strong style={{ color: "black" }}>City</strong></label>
                                                        <Select
                                                            id="citys"
                                                            className='search-line'
                                                            placeholder='Search...'
                                                            // options={cityes}
                                                            options={cityapi.map((sponsor) =>
                                                            ({
                                                                label: sponsor.label,
                                                                value: sponsor.value
                                                            }))}
                                                            // onChange={opt => console.log(opt.value)}
                                                            onChange={opt => setcity(opt.value)}
                                                        />
                                                    </div>
                                                </div>


                                                <div className="col-md-4">
                                                    <div className="form-group mb-0">
                                                        <label><strong style={{ color: "black" }}>Pincode</strong></label>
                                                        <input
                                                            id="Pincode"
                                                            className='form-control'
                                                            placeholder="Enter Pincode"
                                                            value={pincode}
                                                            type="text"
                                                            maxlength="6" min="6" max="6"
                                                            onChange={(e) => setpincode(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group mb-0">
                                                        {/* <label><strong style={{ color: "black" }}>lat</strong></label> */}
                                                        <input
                                                            name="lat" id="lat"
                                                            type="hidden"
                                                            className="form-control"
                                                            placeholder="Enter Lat"
                                                            value={lat}
                                                        // onChange={(e) => setlat(e.target.value)}
                                                        />
                                                    </div>
                                                </div>


                                                <div className="col-md-4">
                                                    <div className="form-group mb-0 my-3">
                                                        <label style={{ color: "#000000" }}>Geolocation <i className="fa fa-map-marker mx-1" style={{ fontsize: "36px", color: "red" }}></i></label>
                                                        <button type="button" className="form-control" onClick={getMyLocation}>Get Geolocation</button>
                                                    </div>
                                                </div>




                                                <div className="col-md-4">
                                                    <div className="form-group mb-0">
                                                        {/* <label><strong style={{ color: "black" }}>Lang</strong></label> */}
                                                        <input
                                                            name="lang" id="lang"
                                                            type="hidden"
                                                            className="form-control"
                                                            placeholder="Emnter Lung"
                                                            value={lng}
                                                        // onChange={(e) => setlang(e.target.value)}
                                                        />
                                                    </div>
                                                </div>




                                                <div className='col-md-12'> {errorDiv} <hr /> </div>

                                                <div className="col-md-12">
                                                    <br />
                                                    <div className='text-center'>
                                                        <button className="btn btn-primary mx-2" type='submit'> Search</button>
                                                        <button className="btn btn-info mx-2" type="button" onClick={refreshPage}> Reset </button>
                                                    </div>
                                                    <br /><hr /><br />
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div id="displaydata" className='col-md-12'> </div>
                                    <div id="nofound" className='col-md-12 text-center' style={{ display: 'none' }} ><h5>No Any Data Found</h5></div>
                                    <div id="searchdata" className='col-md-12 text-center' style={{ display: 'block' }}><h5>  Please Search </h5></div>
                                    <div className="col-md-12">
                                        <div className='text-center'>
                                            <div className="ajax-load1 text-center" style={{ display: 'none' }}>
                                                <img src="assets/loader.gif" />
                                            </div>
                                            <input type="hidden" id="pageno" value='0' />
                                            <div id="lodebutton" style={{ display: 'none' }}>
                                                <button onClick={loadMore} className="btn btn-info">LoadMore</button>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </section >

            <Footer />
        </React.Fragment >
    )
}
