import React from 'react'
import MrHeader from '../Comman/MRHeader';
import MrSidebar from '../Comman/MRSidebar';
import { useHistory, useParams } from 'react-router-dom';
import { useState, useEffect } from "react"
import Swal from 'sweetalert2';
import api from '../../Comman_Admin/axios';
import { imageurl } from '../../Comman_Admin/imagecommon';



export default function MrProfile() {

    const [name, setname] = useState('');
    const [email, setemail] = useState('');
    const [phone, setphone] = useState('');
    const [zone, setzone] = useState('');
    const [state, setstate] = useState("");
    const [emp_code, setemp_code] = useState("");
    const [designation, setdesignation] = useState("");
    const [headquater, setheadquater] = useState("");
    const [profile_pic, setprofile_pic] = useState([]);

    const [values, setValues] = useState({
        name: '',
        email: "",
        phone: "",
        zone: "",
        state: "",
        emp_code: "",
        designation: "",
        headquater: "",
        profile_pic: ""
    });

    const validateEmail = (email) => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    const [error, setError] = useState(null)
    const [error1, setError1] = useState(null)


    const errorDiv = error
        ? <div className="text-center alert alert-danger">
            {error}
        </div>
        : '';

    const errorDiv1 = error1
        ? <div className="text-center alert alert-danger">
            {error1}
        </div>
        : '';
    const { id } = useParams();
    let history = useHistory();

    useEffect(() => {
        admindataget()
    }, [])


    function admindataget(req, res) {
        try {
            let customToken = sessionStorage.getItem('customToken');
            if (customToken) {
                let headers = {
                    Authorization:
                        "Bearer " + customToken,
                    "Content-Type": "application/json",
                };
                api.get(`/employee/get_profile`, { headers })
                    .then(res => {
                        const tableData = res.data.data[0];
                        setValues({
                            name: tableData.name,
                            email: tableData.email,
                            phone: tableData.phone,
                            zone: tableData.zone,
                            state: tableData.state,
                            emp_code: tableData.emp_code,
                            designation: tableData.designation,
                            headquater: tableData.headquater,
                            profile_pic: tableData.profile_pic
                            // { imageurl.url + '/' + rowData.profile_pic }
                        })
                        setname(tableData.name);
                        setphone(tableData.phone);
                        setemail(tableData.email);
                        setzone(tableData.zone);
                        setstate(tableData.state);
                        setemp_code(tableData.emp_code);
                        setdesignation(tableData.designation);
                        setheadquater(tableData.headquater);
                        setprofile_pic(tableData.profile_pic);
                    })
            }
        } catch (err) {
            res.status(404).json({ success: false, message: err.message })
        }
    }


    function handleSubmit(event) {
        event.preventDefault()
        setError(null);

        if (name.toString().trim().length < 0 || name.toString().trim().length < 3) {
            setError('Full Name Must Be at least 3 character Long');
        }
        else if (!validateEmail(email)) {
            setError('Invalid Email');
        }
        else {
            let item = {
                name: name,
                phone: phone,
                email: email,
                zone: zone,
                state: state,
                emp_code: emp_code,
                designation: designation,
                headquater: headquater
            }
            let customToken = sessionStorage.getItem('customToken');

            if (customToken) {
                let headers = {
                    Authorization:
                        "Bearer " + customToken,
                    "Content-Type": "application/json",
                };
                api.post(`/employee/edit_profile`, item, { headers }).then((res) => {
                    Swal.fire({
                        position: 'top-center',
                        icon: 'success',
                        title: 'Profile Update Successfully',
                        showConfirmButton: false,
                        timer: 1000,
                    })
                    console.log(item)
                    setTimeout(() => {
                        history.push('/mr/dashboard')
                        // window.location.reload(true)
                    }, 1000);
                }).catch(err => {
                    if (err.response) {
                        setError(err.response.data.message)
                    }
                    else if (err.request) {
                        setError(err.request);
                    }
                    else {
                        setError(err.message);
                    }
                })
            }

        }
    }


    return (
        <React.Fragment>
            <MrHeader />
            <div className="main-container" id="container">
                <MrSidebar />
                <div id="content" className="main-content">
                    <div className="container">
                        <div className="layout-px-spacing layout-spacing layout-top-spacing center">
                            <div className="row">
                                <div id="flRegistrationForm" className="col-lg-12 layout-spacing">
                                    <div className="statbox widget box box-shadow">
                                        <div className="widget-content widget-content-area">
                                            <div className="row col-xl-12 col-md-12 col-sm-12 col-12 text-left">
                                                <h4>MR Profile</h4>
                                            </div>
                                            <hr />
                                            <div className="text-center user-info mb-4">
                                                <img
                                                    src={imageurl.url + '/' + profile_pic} alt='' height='80' width='100'
                                                />
                                            </div>
                                            <form onSubmit={handleSubmit} >
                                                <div className='row'>
                                                    <div className="form-group mb-4 col-md-6">
                                                        <label><strong style={{ color: "black" }}>Name</strong></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name"
                                                            placeholder="Enter Full Name"
                                                            value={name}
                                                            required
                                                            onChange={(e) => setname(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-4 col-md-6">
                                                        <label><strong style={{ color: "black" }}>Phone</strong></label>
                                                        <input
                                                            type="text"
                                                            className="form-control filterme"
                                                            id="phone"
                                                            placeholder="Enter Phone Number"
                                                            value={phone}
                                                            required
                                                            min="10" max="10" maxLength="10"
                                                            onChange={(e) => setphone(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-4 col-md-6">
                                                        <label><strong style={{ color: "black" }}>Email</strong></label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            id="email"
                                                            placeholder="Enter Email"
                                                            value={email}
                                                            required
                                                        // onChange={(e) => setemail(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-4 col-md-6">
                                                        <label><strong style={{ color: "black" }}>Zone</strong></label>
                                                        <input
                                                            type="zone"
                                                            className="form-control"
                                                            id="zone"
                                                            placeholder="Enter Zone"
                                                            value={zone}
                                                            required
                                                            onChange={(e) => setzone(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="form-group mb-4 col-md-6">
                                                        <label><strong style={{ color: "black" }}>State</strong></label>
                                                        <input
                                                            type="state"
                                                            className="form-control"
                                                            id="state"
                                                            placeholder="Enter State"
                                                            value={state}
                                                            required
                                                            onChange={(e) => setstate(e.target.value)}
                                                        />
                                                    </div> <div className="form-group mb-4 col-md-6">
                                                        <label><strong style={{ color: "black" }}>Emp Code</strong></label>
                                                        <input
                                                            type="emp_code"
                                                            className="form-control"
                                                            id="emp_code"
                                                            placeholder="Enter Emp Code"
                                                            value={emp_code}
                                                            required
                                                        // onChange={(e) => setemp_code(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-4 col-md-6">
                                                        <label><strong style={{ color: "black" }}>Designation</strong></label>
                                                        <input
                                                            type="designation"
                                                            className="form-control"
                                                            id="designation"
                                                            placeholder="Enter Designation"
                                                            value={designation}
                                                            required
                                                            onChange={(e) => setdesignation(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-4 col-md-6">
                                                        <label><strong style={{ color: "black" }}>Headquater</strong></label>
                                                        <input
                                                            type="headquater"
                                                            className="form-control"
                                                            id="headquater"
                                                            placeholder="Enter Headquater"
                                                            value={headquater}
                                                            required
                                                            onChange={(e) => setheadquater(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className='col-md-12'> {errorDiv} <hr /> </div>

                                                    <div className="form-group form-check pl-0 mt-5 text-center col-md-12">
                                                        <button type="submit" className="btn btn-primary mx-2">
                                                            Submit
                                                        </button>
                                                        <button type="submit" className="btn btn-info mx-2 " onClick={() => history.goBack()}>
                                                            Go Back
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}
