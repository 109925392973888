import React from 'react'
import AdminHeader from '../../Comman/AdminHeader'
import AdminSidebar from '../../Comman/AdminSidebar'
import api from '../../../Comman_Admin/axios'
import { Link, useHistory, useParams } from 'react-router-dom';
import { useState, useEffect } from "react"
import Swal from 'sweetalert2';
import { imageurl } from "../../../Comman_Admin/imagecommon"
import MaterialTable from 'material-table';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles } from "@material-ui/core/styles";
import $ from 'jquery'
window.jquery = window.$ = $


const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiPaper-root": {
            borderRadius: "0px",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0);"
        },
        "& .MuiTableSortLabel-icon ": {
            opacity: 1
        }
    },
}));


export default function AdminReport() {

    //////////////// Empoley  List //////////////////

    const [error, setError] = useState(null)
    const errorDiv = error
        ? <div className="text-center alert alert-danger">
            {error}
        </div>
        : '';


    const [user, setuser] = useState([]);

    let history = useHistory();
    const { id } = useParams();

    useEffect(() => {
        adminMrlist()
    }, []);

    function adminMrlist(res) {
        try {
            let customToken = sessionStorage.getItem('customToken');
            if (customToken) {
                let headers = {
                    Authorization: "Bearer " + customToken,
                    "Content-Type": "application/json",
                };
                api.post(`/admin/employee_report`, { headers })
                    .then(res => {
                        const tableData = res.data.data;
                        console.log(tableData);
                        setuser(tableData);
                    })
            }
        } catch (err) {
            res.status(404).json({ success: false, message: err.message })
        }
    }
    //////////////// Empoley  List //////////////////


    //////////////// Empoley Filter List //////////////////

    const [start_date, setstart_date] = useState();
    const [end_date, setend_date] = useState();

    let item;
    function AdminMRdateFilter(event) {
        event.preventDefault()
        setError(null);

        // $('.loader').show();
        // var splitradio = game_market.split("-");
        // var game_market1 = splitradio[0];
        if (start_date == '') {
            setError('Please Select Start Data');
        }
        else if (end_date == '') {
            setError('Please Select End Data');
        }
        else if (end_date < start_date) {
            setError('End Date Garter Then Start Date');
            return
        }
        else {

            item = {
                start_date: start_date,
                end_date: end_date,
            }
            let customToken = sessionStorage.getItem('customToken');
            if (customToken) {
                let headers = {
                    Authorization:
                        "Bearer " + customToken,
                    "Content-Type": "application/json",
                };
                api.post(`/admin/employee_report`, item, { headers })
                    .then(res => {
                        $('.loader').hide();
                        const tableData = res.data.data;
                        setuser(tableData);
                    }).catch(err => {
                        $('.loader').hide();
                        if (err.response) {
                            setError(err.response.data.error.message)
                        }
                        else if (err.request) {
                            setError(err.request.error.message);
                        }
                        else {
                            setError(err.message);
                        }
                    })
            }
        }
    }


    //////////////// Empoley Filter List //////////////////




    function viewChemist(id) {
        history.push(`/admin/employee_report_chemist/${id}`, { "employee_id": id, "start_date": start_date, "end_date": end_date });
        // console.log({ "employee_id": id, "start_data": start_date, "end_date": end_date })
    }

    function viewDoctor(id) {
        history.push(`/admin/employee_report_doctor/${id}`, { "employee_id": id, "start_date": start_date, "end_date": end_date });
        // console.log({ "employee_id": id, "start_data": start_date, "end_date": end_date })
    }


    const classes = useStyles();

    const columns = [
        {
            title: 'ID', field: 'id'
        },
        {
            title: 'Emp ID', field: 'emp_code'
        },
        {
            title: 'Name', field: 'name'
        },
        {
            title: 'Phone', field: 'phone'
        },
        {
            title: 'Email', field: 'email'
        },
        {
            title: 'Doctor Count', field: 'doctor_count'
        },
        {
            title: 'Chemist Count', field: 'chemist_count'
        },
        // {
        //     title: "Image", field: "profile_pic", render: (rowData) => <img src={imageurl.url + '/' + rowData.profile_pic} style={{ width: "120px", height: "100px", borderRadius: '50%', transition: "1s" }} />
        // },
    ]

    return (
        <React.Fragment>
            <AdminHeader />
            <div className="main-container" id="container">
                <AdminSidebar />
                <div id="content" className="main-content">
                    <div className="layout-px-spacing layout-spacing layout-top-spacing center">
                        <div className="row layout-spacing">
                            <div className="col-lg-12">
                                <div className="statbox widget box box-shadow">
                                    <div className="widget-content widget-content-area">
                                        <div className="table-responsive mb-4">
                                            <div className="row">
                                                <div className="col-xl-6 col-md-6 col-sm-6 col-6">
                                                    <h4>Admin Report</h4>
                                                </div>
                                                {/* <div className="col-xl-6 col-md-6 col-sm-6 col-6 text-right my-2">
                                                    <Link to="/admin/addmr"><button className="btn btn-primary"> Add MR </button></Link>
                                                </div> */}
                                                <br /><br /><br />
                                                <div className='col-md-12'>
                                                    <form onSubmit={AdminMRdateFilter}>
                                                        <div className='row'>
                                                            <div className="col-md-4">
                                                                <div className="form-group mb-0">
                                                                    <label><strong style={{ color: "black" }}>Start Date</strong></label>
                                                                    <input id="basicFlatpickr"
                                                                        className="form-control flatpickr flatpickr-input active"
                                                                        type="date"
                                                                        placeholder="Select Date.."
                                                                        required
                                                                        value={start_date}
                                                                        onChange={(e) => setstart_date(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group mb-0">
                                                                    <label><strong style={{ color: "black" }}>End Date</strong></label>
                                                                    <input id="basicFlatpickr"
                                                                        className="form-control flatpickr flatpickr-input active"
                                                                        type="date"
                                                                        placeholder="Select Date.."
                                                                        required
                                                                        value={end_date}
                                                                        onChange={(e) => setend_date(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className='col-md-4 text-center'>
                                                                <br />
                                                                <div className='loader' style={{ display: 'none' }}>
                                                                    <img src="/assets/loader.gif" />
                                                                </div>
                                                            </div>
                                                            <br /><br /><br />

                                                            <div className='col-md-12'> {errorDiv} <hr /> </div>


                                                            <div className="col-md-12">
                                                                <br />
                                                                <div className='text-center'>
                                                                    <button className="btn btn-primary" type='submit'> Search </button>
                                                                </div>
                                                                <br /><hr /><br />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className={classes.root}>
                                                <MaterialTable
                                                    title=""
                                                    data={user}
                                                    columns={columns}
                                                    options={{
                                                        sorting: true, search: true,
                                                        searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
                                                        paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
                                                        paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both",
                                                        actionsColumnIndex: -1, draggable: false,
                                                        headerStyle: { color: "black", opacity: 1 }
                                                    }}
                                                    actions={
                                                        [
                                                            {
                                                                // icon: () => <VisibilityIcon />,
                                                                icon: rowData =>
                                                                    <button style={{ fontSize: "12px" }} className="btn btn-primary btn-sm">View<br />Doctor</button>,
                                                                tooltip: 'View Doctor',
                                                                onClick: (event, rowData) => viewDoctor(rowData.id),
                                                            },
                                                            {
                                                                icon: rowData =>
                                                                    <button style={{ fontSize: "12px" }} className="btn btn-primary btn-sm">View<br />Chemist</button>,
                                                                tooltip: 'View Chemist',

                                                                onClick: (event, rowData) => viewChemist(rowData.id),
                                                            },

                                                        ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}
