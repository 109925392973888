import React from 'react'
import AdminHeader from '../../Comman/AdminHeader'
import AdminSidebar from '../../Comman/AdminSidebar'
import api from '../../../Comman_Admin/axios'
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from "react"
import Swal from 'sweetalert2';
import XLSX from 'xlsx'

import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import { data } from 'jquery';



const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiPaper-root": {
            borderRadius: "0px",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0);"
        },
        "& .MuiTableSortLabel-icon ": {
            opacity: 1
        }
    },
}));

export default function AdminChemistTable() {

    const [user, setuser] = useState([])

    let history = useHistory();

    useEffect(() => {
        adminChemistlist()
    }, []);

    function adminChemistlist(req, res) {
        try {
            let customToken = sessionStorage.getItem('customToken');
            if (customToken) {
                let headers = {
                    Authorization:
                        "Bearer " + customToken,
                    "Content-Type": "application/json",
                };
                api.get(`/admin/get_chemist`, { headers })
                    .then(res => {
                        const tableData = res.data.data;
                        setuser(tableData);
                        console.log(tableData)
                    })
            }
        } catch (err) {
            res.status(404).json({ success: false, message: err.message })
        }

    }



    function updateChemist(id) {

        history.push(`/admin/editchemist/${id}`);
    }

    function deleteuser(id, res) {
        try {
            let customToken = sessionStorage.getItem('customToken');
            if (customToken) {
                let headers = {
                    Authorization: "Bearer " + customToken,
                    "Content-Type": "application/json",
                };
                if (customToken) {
                    Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            api.delete(`/admin/delete_chemist/${id}`, { headers }).then((result) => {
                                adminChemistlist()
                            })
                        }
                    })
                }
            }
        } catch (err) {
            res.status(404).json({ success: false, message: err.message })
        }
        adminChemistlist()
    }



    const classes = useStyles();

    const columns = [
        {
            title: "Id", field: "id"
        },
        {
            title: 'Chemist Name', field: 'chemist_shop'
        },
        {
            title: 'Contact Number', field: 'contact_no'
        },
        {
            title: 'Address', field: 'address_line1'
        },
        {
            title: 'City', field: 'city_name'
        },
        {
            title: 'State', field: 'state_name'
        },
    ]

    const downloadExcel = () => {
        const newData = user.map(row => {
            delete row.tableData
            return row
        })
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "students")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "ChemistData.xlsx")
    }



    return (
        <React.Fragment>
            <AdminHeader />
            <div className="main-container" id="container">
                <AdminSidebar />
                <div id="content" className="main-content">
                    <div className="layout-px-spacing layout-spacing layout-top-spacing center">
                        <div className="row layout-spacing">
                            <div className="col-lg-12">
                                <div className="statbox widget box box-shadow">
                                    <div className="widget-content widget-content-area">
                                        <div className="table-responsive mb-4">


                                            <div className="row">
                                                <div className="col-xl-6 col-md-6 col-sm-6 col-6">
                                                    <h4>Chemist List</h4>
                                                </div>

                                                {/* <div className="col-xl-6 col-md-6 col-sm-6 col-6 text-right my-2">
                                                    <a href="/admin/addchemist"><button className="btn btn-primary"> Add Chemist </button></a>
                                                </div> */}
                                            </div>

                                            <div className={classes.root}>

                                                <MaterialTable title=""
                                                    data={user}
                                                    columns={columns}
                                                    options={{
                                                        sorting: true, search: true,
                                                        // exportButton: true,
                                                        // exportAllData: true, exportFileName: "ChemistData", addRowPosition: "first", actionsColumnIndex: -1, exportCsv: handleExportCsv,
                                                        searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
                                                        paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
                                                        paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both",
                                                        actionsColumnIndex: -1, draggable: false,
                                                        headerStyle: { color: "black" }
                                                    }}

                                                    actions={[
                                                        {
                                                            icon: 'edit',
                                                            tooltip: 'Edit User',
                                                            onClick: (event, rowData) => updateChemist(rowData.id),

                                                        },
                                                        {
                                                            icon: 'delete',
                                                            tooltip: 'Delete User',
                                                            onClick: (event, rowData) => deleteuser(rowData.id)

                                                        },
                                                        {
                                                            icon: () => <button className='btn btn-info'>Export</button>,// you can pass icon too
                                                            tooltip: "Export to Excel",
                                                            onClick: () => downloadExcel(),
                                                            isFreeAction: true
                                                        }

                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
