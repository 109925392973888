import React from 'react'
import "./Slick.css"
// import "./slickthem.css"
import $ from 'jquery'
window.jquery = window.$ = $


export default function Slider() {


    const $jq = $.noConflict();
    $jq(document).ready(function () {
        $jq('.slider-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.slider-nav',
        });
        $jq('.slider-nav').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            dots: false,
            focusOnSelect: true,
        });
    });



    return (
        <section className="aboutbph py-5" id="aboutbph">
            <div className="container">
                <div className="sectiontitle text-center">
                    <h1>About BPH</h1>
                </div>
                <div className="col-sm-12">
                    <div className="row">
                        <section className="regular slider slider-nav">
                            <div className="text-center">
                                <figure>
                                    <img
                                        src="assets/images/aboutbph/luts.png"
                                        className="img-fluid mainimg"
                                    />
                                </figure>
                                <h3 className="py-2 m-0">What is LUTS?</h3>
                                <div className="arrow-down" />
                            </div>
                            <div className="text-center">
                                <figure>
                                    <img
                                        src="assets/images/aboutbph/knowmoreabtprostrate.png"
                                        className="img-fluid mainimg"
                                    />
                                </figure>
                                <h3 className="py-2 m-0"> About Prostrate</h3>
                                <div className="arrow-down" />
                            </div>
                            <div className="text-center">
                                <figure>
                                    <img
                                        src="assets/images/aboutbph/expvideos.png"
                                        className="img-fluid mainimg"
                                    />
                                </figure>
                                <h3 className="py-2 m-0">Prostrate Health &amp; BPH Facts</h3>
                                <div className="arrow-down" />
                            </div>
                            <div className="text-center">
                                <figure>
                                    <img
                                        src="assets/images/aboutbph/symptomsofbph.png"
                                        className="img-fluid mainimg"
                                    />
                                </figure>
                                <h3 className="py-2 m-0">Symptoms of BPH</h3>
                                <div className="arrow-down" />
                            </div>
                            <div className="text-center">
                                <figure>
                                    <img
                                        src="assets/images/aboutbph/dosanddont.png"
                                        className="img-fluid mainimg"
                                    />
                                </figure>
                                <h3 className="py-2 m-0">Do's &amp; Dont's</h3>
                                <div className="arrow-down" />
                            </div>
                            <div className="text-center">
                                <figure>
                                    <img
                                        src="assets/images/aboutbph/undertandingbph.png"
                                        className="img-fluid mainimg"
                                    />
                                </figure>
                                <h3 className="py-2 m-0">Understanding BPH</h3>
                                <div className="arrow-down" />
                            </div>
                            <div className="text-center">
                                <figure>
                                    <img
                                        src="assets/images/aboutbph/lifestyle-managment.png"
                                        className="img-fluid mainimg"
                                    />
                                </figure>
                                <h3 className="py-2 m-0">Lifestyle Modification</h3>
                                <div className="arrow-down" />
                            </div>
                        </section>
                        <div className="col-sm-12 m-2">
                            <div className="content p-4 slider-for">
                                <div className="col-sm-12" id="luts">
                                    <div className="row">
                                        <h1 style={{ color: "white" }}>WHAT IS LUTS ?</h1>
                                        <p className="m-0" style={{ color: "white" }}>
                                            LUTS is a popularly-used acronym for LOWER URINARY TRACT
                                            SYMPTOMS. It means symptoms, i.e. problems reported by
                                            patients, that are caused by diseases or disorders of the
                                            lower urinary tract â€” which includes the urinary bladder,
                                            prostate and urethra. They are wide ranging and caused by
                                            diverse conditions and of all severities.
                                        </p>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-sm-5">
                                                    <figure className="tab1img">
                                                        <img
                                                            src="assets/images/aboutbph/mb1.png"
                                                            className="img-fluid"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="col-sm-7">
                                                    <h4 className="mt-3" style={{ color: "white" }}>
                                                        Categorization of LUTS due to BPH
                                                    </h4>
                                                    <div className="ulbox">
                                                        <h5 style={{ color: "white" }}>Storage:</h5>
                                                        <ul>
                                                            <li>
                                                                Passing urine several more times during the day than
                                                                normal, within 2 hours from previous urination
                                                            </li>
                                                            <li>

                                                                Nocturia i.e getting up at night to pass urine
                                                            </li>
                                                            <li>
                                                                urgency i.e strong desire to void urine immediately
                                                                with inability to postpone
                                                            </li>
                                                            <li>
                                                                Urge incontinence i.e urgency with undesired leakage
                                                                of urine before reaching the loo
                                                            </li>
                                                        </ul>
                                                        <div className="ulbox">
                                                            <h5 style={{ color: "white" }}>Voiding:</h5>
                                                            <ul>
                                                                <li>
                                                                    Hesitancy i.e difficulty in starting flow of urine
                                                                </li>
                                                                <li>Weak stream of urine</li>
                                                                <li>
                                                                    Intermittency i.e on-and-off starting and stopping
                                                                    of urinary flow during urination, straining or
                                                                    pushing harder than normal to pass urine
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="ulbox">
                                                            <h5 style={{ color: "white" }}>Post-micturition:</h5>
                                                            <ul>
                                                                <li>
                                                                    Feeling unsatisfied owing to incomplete emptying
                                                                    of bladder and dribbling of urine even after
                                                                    person has stopped urinating and might have even
                                                                    left the loo.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12" id="aboutprostrate">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h1 style={{ color: "white" }}>Know More About Prostrate</h1>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <h5 style={{ color: "white" }}>What is Prostate :</h5>
                                                    <p style={{ color: "white" }}>
                                                        Prostate, a small walnut-sized gland, is an organ of the
                                                        male reproductive system. It is present below the neck
                                                        of the bladder â€” which is a narrow outlet leading to a
                                                        narrow passage, called urethra, through which urine
                                                        flows out of the bladder during urination.
                                                    </p>
                                                </div>
                                                <div className="col-sm-6">
                                                    <h5 style={{ color: "white" }}>Function of Prostate</h5>
                                                    <p style={{ color: "white" }}>
                                                        The main function of the prostate is to control the exit
                                                        point of two important body fluids in a male - urine and
                                                        semen.
                                                    </p>
                                                </div>
                                                <div className="col-sm-6 offset-sm-3">
                                                    <figure className="w-50 m-auto">
                                                        <img
                                                            src="assets/images/aboutbph/mb2.png"
                                                            className="img-fluid"
                                                        />
                                                    </figure>
                                                    <p className="text-center" style={{ color: "white" }}>
                                                        The main function of the prostate is to control the exit
                                                        point of two important body fluids in a male - urine and
                                                        semen.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12" id="bphfacts">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h1 style={{ color: "white" }}>
                                                PROSTATE HEALTH
                                                <br />
                                                AND BPH FACTS
                                            </h1>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-2  text-center">
                                                    <p style={{ color: "white" }}>
                                                        It has been said that all men will have an enlarged
                                                        prostate if they live long enough.
                                                    </p>
                                                </div>
                                                <div className="col-sm-2 text-center">
                                                    <p style={{ color: "white" }}>
                                                        BPH being non-cancerous condition, is not usually life
                                                        threatening
                                                    </p>
                                                </div>
                                                <div className="col-sm-2 text-center">
                                                    <p style={{ color: "white" }}>
                                                        Chances of BPH increases with increasing age, therefore
                                                        men above50 years must visit a specialist once a year
                                                        and get checked for symptoms
                                                    </p>
                                                </div>
                                                <div className="col-sm-2  text-center">
                                                    <p style={{ color: "white" }}>Untreated BPH can lead to kidney damage. </p>
                                                </div>
                                                <div className="col-sm-2 text-center">
                                                    <p style={{ color: "white" }}>Untreated BPH can lead to acute urinary retention. </p>
                                                </div>
                                                <div className="col-sm-2 text-center">
                                                    <p style={{ color: "white" }}>BPH can cause severe urinary tract infections. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12" id="symptomsofbph">
                                    <div className="row">
                                        <div className="col-sm-12 text-center">
                                            <h1 style={{ color: "white" }}>
                                                ARE YOU EXPERIENCING ANY OF THESE
                                                <br />
                                                SYMPTOMS OF BPH
                                            </h1>
                                            <h5 style={{ color: "white" }}>Know Your Symptoms</h5>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-sm-3  text-center">
                                                    <figure className="mb3 ">
                                                        <img
                                                            src="assets/images/aboutbph/mb4-1.png"
                                                            className="img-fluid"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="col-sm-3 text-center">
                                                    <figure className="mb3 ">
                                                        <img
                                                            src="assets/images/aboutbph/mb4-2.png"
                                                            className="img-fluid"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="col-sm-3 text-center">
                                                    <figure className="mb3 ">
                                                        <img
                                                            src="assets/images/aboutbph/mb4-3.png"
                                                            className="img-fluid"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="col-sm-3  text-center">
                                                    <figure className="mb3">
                                                        <img
                                                            src="assets/images/aboutbph/mb4-4.png"
                                                            className="img-fluid"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="col-sm-3 text-center">
                                                    <figure className="mb3 ">
                                                        <img
                                                            src="assets/images/aboutbph/mb4-5.png"
                                                            className="img-fluid"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="col-sm-3 text-center">
                                                    <figure className="mb3 ">
                                                        <img
                                                            src="assets/images/aboutbph/mb4-6.png"
                                                            className="img-fluid"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="col-sm-3 text-center">
                                                    <figure className="mb3 ">
                                                        <img
                                                            src="assets/images/aboutbph/mb4-7.png"
                                                            className="img-fluid"
                                                        />
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12" id="dosanddonts">
                                    <div className="row">
                                        <div className="col-sm-12 text-center">
                                            <h1 style={{ color: "white" }}>Do's &amp; font's in BPH </h1>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <h5 style={{ color: "white" }}>DO's</h5>
                                                    <p style={{ color: "white" }}> Manage Your Fluids</p>
                                                    <ul>
                                                        <li style={{ color: "white" }}>
                                                            Limit beverages in the evening. Avoid fluids two hours
                                                            before retiring to bed. Restrict beverages, especially
                                                            on long journeys and when outdoors.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-sm-6">
                                                    <h5 style={{ color: "white" }}>DON'Ts </h5>
                                                    <p style={{ color: "white" }}>
                                                        Avoid Foods/Drinks which are bladder irritants like:
                                                    </p>
                                                    <ul>
                                                        <li style={{ color: "white" }}>

                                                            Caffeine-containing beverages e.g. tea, coffee, energy
                                                            drinks, etc.
                                                        </li>
                                                        <li style={{ color: "white" }}>Alcoholic drinks. </li>
                                                        <li style={{ color: "white" }}>Spicy and salty foods. </li>
                                                    </ul>
                                                </div>
                                                <div className="col-sm-12 text-center">
                                                    <p style={{ color: "white" }}>
                                                        Consult your doctor for adjusting the timings and doses
                                                        of your medications for any other condition, to avoid
                                                        the chances of frequent urination.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12" id="understandingbph">
                                    <div className="row">
                                        <div className="col-sm-12 ">
                                            <h1 style={{ color: "white" }}>UNDERSTANDING BPH</h1>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <p style={{ color: "white" }}>
                                                        Normally, urine flows freely out of the bladder
                                                        through the urethra,which in turn passes through the
                                                        middle of the prostate gland. Prostate gland is a
                                                        walnut-shaped and sized structure located at the lower
                                                        opening of the urinary bladder.
                                                    </p>
                                                </div>
                                                <div className="col-sm-6">
                                                    <p style={{ color: "white" }}>
                                                        In BPH, with increasing age, the prostate enlarges.
                                                        This puts pressure on the urethra, thereby
                                                        constricting it and obstructing the urine outflow from
                                                        the bladder.This leads to urine retainment in the
                                                        Enlarged bladder and voiding problems.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12" id="lifestylemodifications">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h1 style={{ color: "white" }}>
                                                LIFESTYLE MODIFICATION
                                                <br />
                                                IN BPH
                                            </h1>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-sm-2  ">
                                                    <p style={{ color: "white" }}>Quit smoking. </p>
                                                </div>
                                                <div className="col-sm-2 ">
                                                    <p style={{ color: "white" }}>
                                                        Exercise regularly:30 minutes of moderate exercise, 5
                                                        days a week.
                                                    </p>
                                                </div>
                                                <div className="col-sm-2  ">
                                                    <p style={{ color: "white" }}>
                                                        Have at least 5 servings of fresh fruits and
                                                        vegetables every day constipation.
                                                    </p>
                                                </div>
                                                <div className="col-sm-2 ">
                                                    <p style={{ color: "white" }}>
                                                        Avoid salty, starchy (maida), sweet and fried foods as
                                                        well as red meat get rid of (beef, pork, mutton,
                                                        lamb).
                                                    </p>
                                                </div>
                                                <div className="col-sm-2  ">
                                                    <p style={{ color: "white" }}>
                                                        Keep warm. Colder temperatures can increase the urge
                                                        to urinate.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
