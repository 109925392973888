import React from 'react'
import AdminHeader from '../../Comman/AdminHeader'
import AdminSidebar from '../../Comman/AdminSidebar'
import api from '../../../Comman_Admin/axios'
import { Link, useHistory, useParams } from 'react-router-dom';
import { useState, useEffect } from "react"
import { Save, Delete, Edit } from "@material-ui/icons";
import Swal from 'sweetalert2';
import { imageurl } from "../../../Comman_Admin/imagecommon"
import MaterialTable from 'material-table';
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiPaper-root": {
            borderRadius: "0px",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0);"
        },
        "& .MuiTableSortLabel-icon ": {
            opacity: 1
        }
    },
}));


export default function AdminMRTable() {
    const [user, setuser] = useState([])

    let history = useHistory();
    const { id } = useParams();

    useEffect(() => {
        adminMrlist()
    }, []);

    function adminMrlist(req, res) {
        try {
            let customToken = sessionStorage.getItem('customToken');
            if (customToken) {
                let headers = {
                    Authorization: "Bearer " + customToken,
                    "Content-Type": "application/json",
                };
                api.get(`/admin/get_employee_list`, { headers })
                    .then(res => {
                        const tableData = res.data.data;
                        setuser(tableData);
                        console.log(tableData);
                    })
            }
        } catch (err) {
            res.status(404).json({ success: false, message: err.message })
        }

    }


    function updateMR(id) {
        history.push(`/admin/editmr/${id}`);
    }

    function deleteuser(id, res) {
        try {
            let customToken = sessionStorage.getItem('customToken');
            if (customToken) {
                let headers = {
                    Authorization: "Bearer " + customToken,
                    "Content-Type": "application/json",
                };
                if (customToken) {
                    Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            api.delete(`/admin/delete_employee/${id}`, { headers }).then((result) => {
                                console.log("result.data", result);
                                adminMrlist()
                            })
                        }
                    })
                }
            }
        } catch (err) {
            res.status(404).json({ success: false, message: err.message })
        }
        adminMrlist()
    }


    const classes = useStyles();

    const columns = [
        {
            title: 'ID', field: 'id'
        },
        {
            title: 'Emp ID', field: 'emp_code'
        },
        {
            title: 'Name', field: 'name'
        },
        {
            title: 'Phone', field: 'phone'
        },
        {
            title: 'Email', field: 'email'
        },
        {
            title: "Image", field: "profile_pic", render: (rowData) => <img src={imageurl.url + '/' + rowData.profile_pic} style={{ width: "120px", height: "100px", borderRadius: '50%', transition: "1s" }} />
        },
    ]

    return (
        <React.Fragment>
            <AdminHeader />
            <div className="main-container" id="container">
                <AdminSidebar />
                <div id="content" className="main-content">
                    <div className="layout-px-spacing layout-spacing layout-top-spacing center">
                        <div className="row layout-spacing">
                            <div className="col-lg-12">
                                <div className="statbox widget box box-shadow">
                                    <div className="widget-content widget-content-area">
                                        <div className="table-responsive mb-4">
                                            <div className="row">
                                                <div className="col-xl-6 col-md-6 col-sm-6 col-6">
                                                    <h4>MR List</h4>
                                                </div>
                                                <div className="col-xl-6 col-md-6 col-sm-6 col-6 text-right my-2">
                                                    <Link to="/admin/addmr"><button className="btn btn-primary"> Add MR </button></Link>
                                                </div>
                                            </div>
                                            <div className={classes.root}>
                                                <MaterialTable
                                                    title=""
                                                    data={user}
                                                    columns={columns}
                                                    options={{
                                                        sorting: true, search: true,
                                                        searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
                                                        paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
                                                        paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both",
                                                        actionsColumnIndex: -1, draggable: false,
                                                        headerStyle: { color: "black", opacity: 1 }
                                                    }}
                                                    actions={
                                                        [
                                                            {
                                                                icon: 'edit',
                                                                tooltip: 'Edit User',
                                                                onClick: (event, rowData) => updateMR(rowData.id),
                                                            },
                                                            {
                                                                icon: 'delete',
                                                                tooltip: 'Delete MR',
                                                                onClick: (event, rowData) => deleteuser(rowData.id)

                                                            },
                                                        ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}
