import React from 'react'
import AdminSidebar from '../../Comman/AdminSidebar';
import AdminHeader from '../../Comman/AdminHeader';
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Switch from "react-switch";
import "./Switch.css"
import api from '../../../Comman_Admin/axios';
import Swal from 'sweetalert2';



function EditMR() {
    const [name, setname] = useState('');
    const [email, setemail] = useState("");
    const [phone, setphone] = useState('');
    const [zone, setzone] = useState('');
    const [state, setstate] = useState('');
    const [emp_code, setemp_code] = useState('');
    const [designation, setdesignation] = useState('');
    const [headquater, setheadquater] = useState('');

    const [password, setpassword] = useState('');



    const [values, setValues] = useState({
        name: '',
        email: "",
        phone: "",
        zone: "",
        state: "",
        designation: "",
        emp_code: "",
        headquater: "",
        password: ""

    });
    const [error, setError] = useState(null);
    const [error1, setError1] = useState(null)


    const errorDiv = error
        ? <div className="text-center alert alert-danger">
            {error}
        </div>
        : '';

    const errorDivpassword = error1
        ? <div className="text-center alert alert-danger">
            {error1}
        </div>
        : '';

    const history = useHistory();
    const { id } = useParams();

    useEffect(() => {
        clientdataget()
    }, [])

    function clientdataget(req, res) {
        try {
            let customToken = sessionStorage.getItem('customToken');
            if (customToken) {
                let headers = {
                    Authorization:
                        "Bearer " + customToken,
                    "Content-Type": "application/json",
                };
                api.get(`/admin/get_employee_by_id/${id}`, { headers })
                    .then(res => {
                        const tableData = res.data.data[0];
                        setValues({
                            name: tableData.name,
                            email: tableData.email,
                            phone: tableData.phone,
                            zone: tableData.zone,
                            state: tableData.state,
                            designation: tableData.designation,
                            emp_code: tableData.emp_code,
                            headquater: tableData.headquater,
                            password: tableData.password
                        })
                        setname(tableData.name);
                        setemail(tableData.email);
                        setphone(tableData.phone);
                        setzone(tableData.zone);
                        setstate(tableData.state);
                        setdesignation(tableData.designation);
                        setemp_code(tableData.emp_code);
                        setheadquater(tableData.headquater);
                        setpassword(tableData.password)
                    })
            }
        } catch (err) {
            res.status(404).json({ success: false, message: err.message })
        }
    }

    function handleSubmit(event) {
        event.preventDefault()
        setError(null);

        if (name.toString().trim().length < 0 || name.toString().trim().length < 3) {
            setError('Dr Name Must Be at least 3 character Long');
        }
        else if (phone.trim().length < 0 || phone.trim().length < 10) {
            setError('Phone Number Must Be at least 10 character Long');
        }

        else {
            let item = {
                employee_id: id,
                name: name,
                email: email,
                phone: phone,
                zone: zone,
                state: state,
                designation: designation,
                emp_code: emp_code,
                headquater: headquater
            }
            console.log(item)
            let customToken = sessionStorage.getItem('customToken');
            if (customToken) {
                let headers = {
                    Authorization:
                        "Bearer " + customToken,
                    "Content-Type": "application/json",
                };
                api.post(`/admin/edit_employee`, item, { headers }).then((res) => {
                    console.log("happy", headers)
                    if (res.data.success === false) {
                        setError(res.data.message);
                    } else {
                        Swal.fire({
                            position: 'top-center',
                            icon: 'success',
                            title: 'MR Details Update Successfully',
                            showConfirmButton: false,
                            timer: 1000,
                        })
                        setTimeout(() => {
                            history.push('/admin/mr')
                            // window.location.reload(true)
                        }, 1000);
                    }
                }).catch(err => {
                    if (err.response) {
                        setError(err.response.data.error.message)
                    }
                    else if (err.request) {
                        setError(err.request);
                    }
                    else {
                        setError(err.message);
                    }
                })
            }
        }
    }

    function handleSubmitPassword(event) {
        event.preventDefault()
        setError1(null);

        if (password.toString().trim().length < 0 || password.toString().trim().length < 6) {
            setError1('Password must be at least 6 character long');
        } else {

            let item = {
                employee_id: id,
                password: password,
            }
            console.log(item)
            let customToken = sessionStorage.getItem('customToken');
            if (customToken) {
                let headers = {
                    Authorization:
                        "Bearer " + customToken,
                    "Content-Type": "application/json",
                };
                api.post(`/admin/change_employee_password`, item, { headers }).then((res) => {
                    if (res.data.success === false) {
                        setError1(res.data.message);
                    } else {
                        Swal.fire({
                            position: 'top-center',
                            icon: 'success',
                            title: 'MR Password Update Successfully',
                            showConfirmButton: false,
                            timer: 1000,
                        })
                        setTimeout(() => {
                            history.push('/admin/mr')
                            // window.location.reload(true)
                        }, 1000);
                    }
                }).catch(err => {
                    if (err.response) {
                        setError1(err.response.data.error.message)
                    }
                    else if (err.request) {
                        setError1(err.request);
                    }
                    else {
                        setError1(err.message);
                    }
                })
            }
        }
    }

    return (
        <React.Fragment>
            <AdminHeader />
            <div className="main-container" id="container">
                <AdminSidebar />
                <div id="content" className="main-content">
                    <div className="container layout-spacing layout-top-spacing center">
                        <div className="row">
                            <div id="flRegistrationForm" className="col-lg-12 layout-spacing">
                                <div className="statbox widget box box-shadow">
                                    <div className="widget-content widget-content-area">
                                        <div className="row col-xl-12 col-md-12 col-sm-12 col-12 text-left">
                                            <h4><strong>MR Details</strong></h4>
                                        </div>
                                        <hr />
                                        <form onSubmit={handleSubmit} >
                                            <div className='row'>
                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Name</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        placeholder="Enter Name"
                                                        value={name}
                                                        required
                                                        onChange={(e) => setname(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Email</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="email"
                                                        placeholder="Enter Email"
                                                        value={email}
                                                        required

                                                    // onChange={(e) => setemail(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Phone</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control filterme"
                                                        id="phone"
                                                        placeholder="Enter Phone Number"
                                                        value={phone}
                                                        required
                                                        onChange={(e) => setphone(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Emp Code</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="emp_code"
                                                        placeholder="Enter Emp Code"
                                                        value={emp_code}
                                                        required

                                                    // onChange={(e) => setemp_code(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Zone</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="zone"
                                                        placeholder="Enter Zone"
                                                        value={zone}
                                                        required
                                                        onChange={(e) => setzone(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>State</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="state"
                                                        placeholder="state"
                                                        value={state}
                                                        required
                                                        onChange={(e) => setstate(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Designation</strong></label>
                                                    <input
                                                        type="designation"
                                                        className="form-control"
                                                        id="designation"
                                                        placeholder="Enter Designation"
                                                        value={designation}

                                                        onChange={(e) => setdesignation(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Headquater</strong></label>
                                                    <input
                                                        type="headquater"
                                                        className="form-control"
                                                        id="headquater"
                                                        placeholder="Enter Headquater"
                                                        value={headquater}

                                                        onChange={(e) => setheadquater(e.target.value)}
                                                    />
                                                </div>
                                                {/* <label className="switch">
                                                <span>Status</span>
                                                <Switch onChange={() => setState(!is_active)} checked={is_active} />
                                            </label> */}

                                                <div className='col-md-12'> {errorDiv} <hr /></div>

                                                <div className="form-group form-check pl-0 mt-5 text-center col-md-12">
                                                    <button type="submit" className="btn btn-primary mx-2">
                                                        Submit
                                                    </button>
                                                    <button type="submit" className="btn btn-info mx-2 " onClick={() => history.goBack()}>
                                                        Go Back
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                            <div id="flRegistrationForm" className="col-lg-12 layout-spacing">
                                <div className="statbox widget box box-shadow">
                                    <div className="widget-content widget-content-area">
                                        <div className="row col-xl-12 col-md-12 col-sm-12 col-12 text-left">
                                            <h4><strong>MR Password</strong></h4>
                                        </div>
                                        <hr />
                                        <form onSubmit={handleSubmitPassword} >
                                            <div className="form-group mb-4">
                                                <label><strong>Password</strong></label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    id="password"
                                                    placeholder="Enter Password"
                                                    // value={password}
                                                    required
                                                    onChange={(e) => setpassword(e.target.value)}
                                                />
                                            </div>

                                            {/* <label className="switch">
                                                <span>Status</span>
                                                <Switch onChange={() => setState(!is_active)} checked={is_active} />
                                            </label> */}

                                            <div className='col-md-12'> {errorDivpassword}  </div>

                                            <div className="form-group form-check pl-0 mt-5 text-center">
                                                <button type="submit" className="btn btn-primary mx-2">
                                                    Submit
                                                </button>
                                                <button type="submit" className="btn btn-info mx-2 " onClick={() => history.goBack()}>
                                                    Go Back
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EditMR