import React from 'react'
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from "react"
import MrHeader from '../../Comman/MRHeader';
import MrSidebar from '../../Comman/MRSidebar';
import api from '../../../Comman_Admin/axios';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
    geocodeByPlaceId
} from 'react-places-autocomplete'
import Select from 'react-select';



import $ from 'jquery'
window.jquery = window.$ = $


export default function MrAddChemist() {


    const [name, setname] = useState('');
    const [chemist_shop, setchemist_shop] = useState("");
    const [contact_no, setcontact_no] = useState('');
    const [address_line1, setaddress_line1] = useState('');
    const [address_line2, setaddress_line2] = useState('');
    const [city, setcity] = useState("");
    const [state, setstate] = useState("");
    const [landmark, setlandmark] = useState("");
    const [pincode, setpincode] = useState("");
    const [lat, setlat] = useState("");
    const [lng, setlang] = useState('');

    const [cityapi, setcityapi] = useState([]);
    const [stateapi, setstateapi] = useState([]);
    const [FullAddress, setFullAddress] = useState([]);


    const [error, setError] = useState(null)
    const errorDiv = error
        ? <div className="text-center alert alert-danger">
            {error}
        </div>
        : '';


    const history = useHistory();


    useEffect(() => {
        IndanStage()
    }, []);

    function IndanStage(res) {
        try {
            api.get(`/employee/get_state`)
                .then(res => {
                    // const tableData = res.data.data;
                    // console.log(tableData)
                    // setstateapi(tableData)
                    const options = res.data.data.map(item => ({
                        label: item.name,
                        value: item.id,
                    }))
                    setstateapi(options)
                })
        }
        catch (err) {
            res.status(404).json({ success: false, message: err.message })
        }
    }

    var krunal = state ? state : ""

    useEffect(() => {
        Citylist()
    }, [krunal]);


    function Citylist(req, res) {
        try {
            api.get(`/employee/get_city/${krunal}`)
                .then(res => {
                    // const tableData = res.data.data;
                    // setcityapi(tableData)
                    const options = res.data.data.map(maincity => ({
                        label: maincity.city_name,
                        value: maincity.id,
                    }))
                    setcityapi(options)
                })
        } catch (err) {
            res.status(404).json({ success: false, message: err.message })
        }
    }


    function handleSubmit(event) {
        event.preventDefault();
        setError(null);

        // if (name.toString().trim().length < 0 || name.toString().trim().length < 3) {
        //     setError('Chemist Name Must Be at least 3 character Long');
        // }
        if (chemist_shop.toString().trim().length < 0 || chemist_shop.toString().trim().length < 3) {
            setError('Chemist Shop Name Must Be at least 3 character Long');
        }
        else if (contact_no.trim().length < 0 || contact_no.trim().length < 10) {
            setError('Contact Number Must Be at least 10 character Long');
        }

        else {

            let item = {
                // name: name,
                chemist_shop: chemist_shop,
                contact_no: contact_no,
                address_line1: address_line1,
                address_line2: address_line2,
                city: city,
                state: state,
                landmark: landmark,
                pincode: pincode,
                lat: String(lat),
                lang: String(lng)
            }
            console.log("chech", item)
            let customToken = sessionStorage.getItem('customToken');
            if (customToken) {
                let headers = {
                    Authorization:
                        "Bearer " + customToken,
                    "Content-Type": "application/json",
                };
                api.post(`/employee/add_chemist`, item, { headers })
                    .then(res => {
                        history.push('/mr/chemist')
                        // window.location.reload(true)

                    }).catch(err => {
                        if (err.response) {
                            setError(err.response.data.error.message)
                        }
                        else if (err.request) {
                            setError(err.request);
                        }
                        else {
                            setError(err.message);
                        }
                    })
            }
        }
    }

    const getMyLocation = (e) => {
        let url = "https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyDj-wLttjp7i7HG0QnRRQccEBDmllpt0E8";
        let location = null;
        let latitude = null;
        let longitude = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation
        }
        if (location) {
            location.getCurrentPosition(function (position) {
                latitude = position.coords.latitude;
                longitude = position.coords.longitude;
                setlat(latitude);
                setlang(longitude);
            })
        }
    }





    const handleSelect = async value => {

        const result = await geocodeByAddress(value);
        console.log(result[0].address_components);
        if (result[0].address_components !== undefined) {
            let addrComp = result[0].address_components;
            for (let i = 0; i < addrComp.length; i++) {
                var typ = addrComp[i].types;
                for (let j = 0; j < typ.length; j++) {
                    if (typ[j] === 'administrative_area_level_1') {
                        var state = addrComp[i].long_name; //store the state
                    }
                    else if (typ[j] === 'locality') {
                        var city = addrComp[i].long_name; //store the city
                    }
                    else if (typ[j] === 'postal_code') {
                        var postal_code = addrComp[i].long_name
                        console.log(postal_code)
                    }

                    else { }

                    if (state != null && city != null && postal_code != null) {
                        setpincode(postal_code);
                        $('#Pincode').attr('readonly', true);
                        setstate(state);
                        setcity(city);
                        //StateCityApi();
                        break;
                    }
                }
            }
        }

        let item = {
            state_name: state,
            city_name: city,
            pincode: postal_code

        }

        api.post(`/employee/add_state_city`, item)
            .then(res => {
                // history.push('/mr/chemist')
                const tableData = res.data.data

                setstate(tableData.state_id);
                setcity(tableData.city_id);
                var sthtml = '';
                var cthtml = '';
                var statedata = [];
                var ctatedata = [];
                api.get(`/employee/get_state`)
                    .then(res => {
                        statedata = res.data.data;
                        for (let ij = 0; ij < statedata.length; ij++) {

                            if (statedata[ij].id === tableData.state_id) {
                                sthtml += '<option   value="' + statedata[ij].id + '" selected>' + statedata[ij].name + '</option>';
                            }
                            else {
                                // sthtml += '<option value="' + statedata[ij].id + '">' + statedata[ij].name + '</option>';
                            }
                        }
                        $('#select2').html(sthtml);
                    })

                api.get(`/employee/get_city/${tableData.state_id}`)
                    .then(res => {
                        ctatedata = res.data.data;
                        for (let ip = 0; ip < ctatedata.length; ip++) {

                            if (ctatedata[ip].id === tableData.city_id) {
                                cthtml += '<option value="' + ctatedata[ip].id + '" selected>' + ctatedata[ip].city_name + '</option>';
                            }
                            else {
                                // cthtml += '<option value="' + ctatedata[ip].id + '">' + ctatedata[ip].city_name + '</option>';
                            }
                        }
                        $('#citys').html(cthtml);
                    })

            }).catch(err => {
                if (err.response) {
                    setError(err.response.data.error.message)
                }
                else if (err.request) {
                    setError(err.request);
                }
                else {
                    setError(err.message);
                }
            })

        const ll = await getLatLng(result[0])

        const sp = value.split(',');
        let area = "";
        for (let i = 1; i < sp.length; i++) {
            area += sp[i];
        }
        setaddress_line1(sp[0]);
        setFullAddress(value);
        setaddress_line2(area);
        // setcoordinates(ll);
        setlat(ll.lat);
        setlang(ll.lng);
    }

    return (
        <React.Fragment>
            <MrHeader />
            <div className="main-container" id="container">
                <MrSidebar />
                <div id="content" className="main-content">
                    <div className="container layout-spacing layout-top-spacing center">
                        <div className="row">
                            <div id="flRegistrationForm" className="col-lg-12 layout-spacing">
                                <div className="statbox widget box box-shadow">
                                    <div className="widget-content widget-content-area">
                                        <div className="row col-xl-12 col-md-12 col-sm-12 col-12 text-left">
                                            <h4><strong> Add Chemist</strong></h4>
                                        </div>
                                        <hr />
                                        <form onSubmit={handleSubmit} >
                                            <div className='row'>
                                                <div className="form-group mb-4 col-md-6">
                                                    {/* <label><strong style={{ color: "black" }}>Chemist Name</strong></label> */}
                                                    <input
                                                        type="hidden"
                                                        className="form-control"
                                                        id="name"
                                                        placeholder="Enter Chemist Name"
                                                        value={name}
                                                        required
                                                        onChange={(e) => setname(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mb-4 col-md-12">
                                                    <label><strong style={{ color: "black" }}>Chemist Shop Name <span style={{ color: "red" }}>*</span></strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        placeholder="Enter Chemist Shop Name"
                                                        value={chemist_shop}
                                                        required
                                                        onChange={(e) => setchemist_shop(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group mb-4 col-md-12">
                                                    <label><strong style={{ color: "black" }}>Contact number <span style={{ color: "red" }}>*</span></strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control filterme"
                                                        id="contact_no"
                                                        placeholder="Enter Contact Number"
                                                        value={contact_no}
                                                        required
                                                        min="10" max="10" maxLength="10"
                                                        onChange={(e) => setcontact_no(e.target.value)}
                                                    />
                                                </div>



                                                <div className="form-group mb-0 col-md-12">
                                                    <label><strong style={{ color: "black" }}>Location Search<a className="fa fa-map-marker mx-2" onClick={getMyLocation} style={{ fontsize: "36px", color: "red" }}> GeoLocation</a></strong></label>
                                                    <PlacesAutocomplete
                                                        className="form-control"
                                                        value={FullAddress}
                                                        onChange={setFullAddress}
                                                        onSelect={handleSelect}
                                                    >
                                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                            <div className="form-group mb-4">
                                                                <input
                                                                    {...getInputProps({
                                                                        placeholder: 'Search Places ...',
                                                                        className: 'location-search-input form-control',
                                                                    })}
                                                                />
                                                                <div className="autocomplete-dropdown-container">
                                                                    {loading && <div>Loading...</div>}
                                                                    {suggestions.map(suggestion => {
                                                                        const className = suggestion.active
                                                                            ? 'suggestion-item--active'
                                                                            : 'suggestion-item';
                                                                        const style = suggestion.active
                                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                                        return (
                                                                            <div
                                                                                {...getSuggestionItemProps(suggestion, {
                                                                                    className,
                                                                                    style,
                                                                                })}
                                                                            >
                                                                                <span>{suggestion.description}</span>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </PlacesAutocomplete>
                                                </div>



                                                <div className="form-group mb-4 col-md-12">
                                                    <label><strong style={{ color: "black" }}>Address Line 1</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="address_line1"
                                                        placeholder="Enter Address Line 1"
                                                        value={address_line1}
                                                        required
                                                        onChange={(e) => setaddress_line1(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group mb-4 col-md-12">
                                                    <label><strong style={{ color: "black" }}>Address Line 2</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="address"
                                                        placeholder="Enter Address Line 2"
                                                        required
                                                        value={address_line2}
                                                        onChange={(e) => setaddress_line2(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>State</strong></label>
                                                    <Select
                                                        id="select2"
                                                        className='search-line'
                                                        placeholder='Search...'
                                                        // options={options}
                                                        options={stateapi.map((sponsor) =>
                                                        ({
                                                            label: sponsor.label,
                                                            value: sponsor.value
                                                        })
                                                        )}
                                                        onChange={opt => setstate(opt.value)}
                                                    />
                                                </div>

                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "#000000" }}>City</strong></label>
                                                    <Select
                                                        id="citys"
                                                        className='search-line'
                                                        placeholder='Search...'
                                                        options={cityapi.map((sponsor) =>
                                                        ({
                                                            label: sponsor.label,
                                                            value: sponsor.value
                                                        }))}
                                                        // onChange={opt => console.log(opt.value)}
                                                        onChange={opt => setcity(opt.value)}
                                                    />
                                                </div>


                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Landmark</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="landmark"
                                                        placeholder="Enter Landmark"
                                                        value={landmark}
                                                        required
                                                        onChange={(e) => setlandmark(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Pincode</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control filterme"
                                                        id="pincode"
                                                        placeholder="Enter Pincode"
                                                        value={pincode}
                                                        required
                                                        maxlength="6" min="6" max="6"
                                                        onChange={(e) => setpincode(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Latitude</strong></label>
                                                    <input
                                                        name="lat" id="lat"
                                                        type="text"

                                                        className="form-control"
                                                        placeholder="Enter Latitude"
                                                        value={lat}
                                                    // onChange={(e) => setlat(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Longitude</strong></label>
                                                    <input
                                                        name="lang" id="lang"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Longitude"
                                                        value={lng}
                                                    // onChange={(e) => setlang(e.target.value)}
                                                    />
                                                </div>




                                                <div className='col-md-12'> {errorDiv} <hr /> </div>


                                                <div className="form-group form-check pl-0 mt-5 text-center col-md-12">
                                                    <button type="submit" className="btn btn-primary mx-2">
                                                        Submit
                                                    </button>
                                                    <button type="submit" className="btn btn-info mx-2 " onClick={() => history.goBack()}>
                                                        Go Back
                                                    </button>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}
