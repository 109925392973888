
import React from 'react'
import AdminHeader from '../../Comman/AdminHeader'
import AdminSidebar from '../../Comman/AdminSidebar'
import api from '../../../Comman_Admin/axios'
import { Link, useHistory, useParams } from 'react-router-dom';
import { useState, useEffect } from "react"
import Swal from 'sweetalert2';
import { imageurl } from "../../../Comman_Admin/imagecommon"
import MaterialTable from 'material-table';
import VisibilityIcon from '@material-ui/icons/Visibility';
import XLSX from 'xlsx'

import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import $ from 'jquery'
window.jquery = window.$ = $



const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiPaper-root": {
            borderRadius: "0px",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0);"
        },
        "& .MuiTableSortLabel-icon ": {
            opacity: 1
        }
    },
}));


export default function AdminDoctorViewDetails(props) {
    const [user, setuser] = useState([]);

    const [start_date, setstart_date] = useState();
    const [end_date, setend_date] = useState();

    const [error, setError] = useState(null)
    const errorDiv = error
        ? <div className="text-center alert alert-danger">
            {error}
        </div>
        : '';
    let history = useHistory();
    const { id } = useParams();
    const location = useLocation();


    useEffect(() => {
        AdminMRdateFilter()
    }, [location]);


    function AdminMRdateFilter() {

        let item = {
            employee_id: location.state.employee_id,
            start_date: location.state.start_date,
            end_date: location.state.end_date,
        }
        console.log(item)
        let customToken = sessionStorage.getItem('customToken');
        if (customToken) {
            let headers = {
                Authorization:
                    "Bearer " + customToken,
                "Content-Type": "application/json",
            };
            api.post(`/admin/employee_report_doctor`, item, { headers })
                .then(res => {
                    const tableData = res.data.data;
                    setuser(tableData);
                }).catch(err => {
                    $('.loader').hide();
                    if (err.response) {
                        setError(err.response.data.error.message)
                    }
                    else if (err.request) {
                        setError(err.request.error.message);
                    }
                    else {
                        setError(err.message);
                    }
                })
        }
    }


    const classes = useStyles();

    const columns = [
        {
            title: 'Dr.Name', field: 'dr_name'
        },
        {
            title: 'Clinic Name', field: 'clinic_name'
        },
        {
            title: 'Phone', field: 'contact_no'
        },
        {
            title: 'Address', field: 'address_line1'
        },
        {
            title: 'Address With google', field: 'address_line2'
        },
    ]

    const downloadExcel = () => {
        const newData = user.map(row => {
            delete row.tableData
            return row
        })
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "students")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "DoctorData.xlsx")
    }


    return (
        <React.Fragment>
            <AdminHeader />
            <div className="main-container" id="container">
                <AdminSidebar />
                <div id="content" className="main-content">
                    <div className="layout-px-spacing layout-spacing layout-top-spacing center">
                        <div className="row layout-spacing">
                            <div className="col-lg-12">
                                <div className="statbox widget box box-shadow">
                                    <div className="widget-content widget-content-area">
                                        <div className="table-responsive mb-4">
                                            <div className="row">
                                                <div className="col-xl-6 col-md-6 col-sm-6 col-6">
                                                    <h4>Doctor Details</h4>
                                                </div>
                                                {/* <div className="col-xl-6 col-md-6 col-sm-6 col-6 text-right my-2">
                                                    <Link to="/admin/addmr"><button className="btn btn-primary"> Add MR </button></Link>
                                                </div> */}
                                                <br /><br /><br />
                                            </div>
                                            <div className={classes.root}>
                                                <MaterialTable
                                                    title=""
                                                    data={user}
                                                    columns={columns}
                                                    options={{
                                                        sorting: true, search: true,
                                                        exportButton: true,
                                                        exportAllData: true, exportFileName: "DoctorData", addRowPosition: "first", actionsColumnIndex: -1,
                                                        searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
                                                        paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
                                                        paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both",
                                                        actionsColumnIndex: -1, draggable: false,
                                                        headerStyle: { color: "black", opacity: 1 }
                                                    }}
                                                    actions={[
                                                        {
                                                            icon: () => <button className='btn btn-info'>Export</button>,// you can pass icon too
                                                            tooltip: "Export to Excel",
                                                            onClick: () => downloadExcel(),
                                                            isFreeAction: true
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}

