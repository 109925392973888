import React from 'react'
import './testBph.css'
import { useState } from 'react'
import Footer from '../Comman/Footer'
import NavBarWar from '../Comman/NavBarWar'

import $ from 'jquery'
import userEvent from '@testing-library/user-event'
window.jquery = window.$ = $

let sum = 0;
let item = 0;
let Mild1;
let Moderate1;
let Severe1;
let scoreper;



export default function TestBph() {


    $(document).ready(function () {
        $('.qly').click(function () {
            $('#qlybox').show();
            var value = $(this).data('value');
            var valuename = $(this).data('name');
            $('#qlyval').text(value);
            $('#qlyvalname').text(valuename);
            $('#qlyval1').text(value);
            $('#qlyvalinput').val(value);
        });
    });

    $(document).ready(function () {
        $(".qul12").click(function () {

            $('#error-message').html('');
            var a = $('#qlyvalinput').val();

            if ($("input[name=sensation]").is(":checked") && $("input[name=stopped_started]").is(":checked")
                && $("input[name=weak_stream]").is(":checked") && $("input[name=defer_urination]").is(":checked")
                && $("input[name=push_strain]").is(":checked") && $("input[name=pash_urine]").is(":checked") && a != '') {


                $('#totalScore').show();
                $('#howserver').show();


            } else {
                $('#error-message').html('<div _ngcontent-swm-c50="" class="col-12"><div _ngcontent-swm-c50="" role="alert" class="alert alert-danger">Please fill all input!</div></div>');
            }


        })
    })

    const [calcution, setclacution] = useState('');

    const [past, setpast] = useState('');
    const [past1, setpast1] = useState('');
    const [past2, setpast2] = useState('');
    const [past3, setpast3] = useState('');
    const [past4, setpast4] = useState('');
    const [past5, setpast5] = useState('');
    const [past6, setpast6] = useState('');

    const [past7, setpast7] = useState('');


    function handleSubmit(event) {
        event.preventDefault();
        item = {
            past: past,
            past1: past1,
            past2: past2,
            past3: past3,
            past4: past4,
            past5: past5,
            past6: past6,
        }
        // console.log(item)
        let cal = past + past1 + past2 + past3 + past4 + past5 + past6;
        console.log(cal)

        // var value = cal,
        sum = cal
            .toString()
            .split('')
            .map(Number)
            .reduce(function (a, b) {
                return a + b;
            }, 0);
        console.log(sum);

        let totalScore = sum;


        if (totalScore <= 7) {
            Mild1 = "Mild"
            console.log("Mild");
            scoreper = totalScore * 8;

        }
        else if (totalScore > 7 && totalScore <= 19) {
            Moderate1 = "Moderate"
            console.log("Modrate");
            scoreper = totalScore * 6.3;

        }
        else if (totalScore > 20 && totalScore <= 35) {
            Severe1 = "Severe"
            console.log("Severe");
            const greaterThan = totalScore - 21;
            scoreper = greaterThan * 4 + 126;

        }


        setclacution(sum);
    }

    // console.log(sum);
    var Happy = sum;
    var Mild2 = Mild1;
    var Moderate2 = Moderate1;
    var Severe2 = Severe1;
    console.log('hey', Happy)
    // const lastsum = sum;
    // console.log("f", lastsum);



    const handleClick = event => {
        event.preventDefault();

        console.log('old value: ');

        // setMessage('New value');
    };

    return (
        <React.Fragment>
            <NavBarWar />
            <main class="tybspage">
                <section class="banner">
                    <div id="demo" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img
                                    class="img-fluid"
                                    src="assets/images/symptom-severity/banner.png"
                                    alt="Los Angeles"
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section class="parasection py-5">
                    <div class="container">
                        <h2>
                            This <span>BPH symptom score</span> will help your doctor to evaluate
                            your
                            <br />
                            condition and understand your <span>BPH symptom severity better.</span>
                            <sup>1,2</sup>
                        </h2>
                        <hr />
                        <h2>
                            Answer each question by selecting the number
                            <br />
                            corresponding to your symptom severity.<sup>1,2</sup>
                        </h2>
                        <hr />
                        <h2>
                            Carry this with you and show it to your doctor
                            <br />
                            during your next appointment
                        </h2>
                    </div>
                </section>

                <section class="checkheader py-4" style={{ backgroundColor: "#efe8ef" }}>
                    <h1>CHECK THE BOX BASED ON YOUR SYMPTOM SEVERITY</h1>
                    <div class="container">
                        <div class="col-sm-12">
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="row">
                                        <h5 class="m-0">KEY:</h5>
                                    </div>
                                    <div class="row">&nbsp;</div>
                                </div>
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="checkinstructions">
                                                <span>0</span> Not at all.
                                            </div>
                                            <div class="checkinstructions">
                                                <span>1</span> Less than 1 in 5 times
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkinstructions">
                                                <span>2</span> Less than half the time.
                                            </div>
                                            <div class="checkinstructions">
                                                <span>3</span> Roughly half the time
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkinstructions">
                                                <span>4</span> More than half the time.
                                            </div>
                                            <div class="checkinstructions">
                                                <span>5</span> Almost always.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="optionbox mt-3">
                    <div class="container">
                        <div class="col-sm-12">
                            <div class="row">
                                <form class="row" onSubmit={handleSubmit} id="our-form">

                                    <div class="col-sm-6">
                                        <div class="">
                                            <div class="box">
                                                <div class="box1">
                                                    <p>
                                                        Over the past month or so, how often have you
                                                        <br />
                                                        had a sensation of not having emptied your
                                                        <br />
                                                        bladder completely after you ﬁnished urinating?
                                                    </p>
                                                </div>
                                                <div class="box2">
                                                    <figure>
                                                        <img src="assets/images/symptom-severity/1.png" />
                                                    </figure>
                                                </div>
                                                <div class="box3">
                                                    <div class="number">
                                                        <button>0</button> <button>1</button> <button>2</button>
                                                        <button>3</button> <button>4</button> <button>5</button>
                                                    </div>
                                                </div>
                                                <div class="box4">
                                                    <div class="radioboxrow">
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="sensation"
                                                                name="sensation"
                                                                value='0'
                                                                onChange={(e) => setpast(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="sensation"
                                                                name="sensation"
                                                                value='1'
                                                                onChange={(e) => setpast(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="sensation"
                                                                name="sensation"
                                                                value='2'
                                                                onChange={(e) => setpast(e.target.value)}

                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="sensation"
                                                                name="sensation"
                                                                value='3'
                                                                onChange={(e) => setpast(e.target.value)}

                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="sensation"
                                                                name="sensation"
                                                                value='4'
                                                                onChange={(e) => setpast(e.target.value)}

                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="sensation"
                                                                name="sensation"
                                                                value='5'
                                                                onChange={(e) => setpast(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="">
                                            <div class="box">
                                                <div class="box1">
                                                    <p>
                                                        Over the past month or so, how often have you
                                                        <br />
                                                        had to urinate again in less than 2 hours from the
                                                        <br />
                                                        last time when you ﬁnished urinating?
                                                    </p>
                                                </div>
                                                <div class="box2">
                                                    <figure>
                                                        <img src="assets/images/symptom-severity/2.png" />
                                                    </figure>
                                                </div>
                                                <div class="box3">
                                                    <div class="number">
                                                        <button>0</button> <button>1</button> <button>2</button>
                                                        <button>3</button> <button>4</button> <button>5</button>
                                                    </div>
                                                </div>
                                                <div class="box4">
                                                    <div class="radioboxrow">
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="urinate_again"
                                                                name="urinate_again"
                                                                value='0'
                                                                onChange={(e) => setpast1(e.target.value)} />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="urinate_again"
                                                                name="urinate_again"
                                                                value='1'
                                                                onChange={(e) => setpast1(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="urinate_again"
                                                                name="urinate_again"
                                                                value='2'
                                                                onChange={(e) => setpast1(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="urinate_again"
                                                                name="urinate_again"
                                                                value='3'
                                                                onChange={(e) => setpast1(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="urinate_again"
                                                                name="urinate_again"
                                                                value='4'
                                                                onChange={(e) => setpast1(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="urinate_again"
                                                                name="urinate_again"
                                                                value='5'
                                                                onChange={(e) => setpast1(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="">
                                            <div class="box">
                                                <div class="box1">
                                                    <p>
                                                        Over the past month or so, how often have
                                                        <br />
                                                        you found that you stopped and started
                                                        <br />
                                                        again several times while you urinated?
                                                    </p>
                                                </div>
                                                <div class="box2">
                                                    <figure>
                                                        <img src="assets/images/symptom-severity/3.png" />
                                                    </figure>
                                                </div>
                                                <div class="box3">
                                                    <div class="number">
                                                        <button>0</button> <button>1</button> <button>2</button>
                                                        <button>3</button> <button>4</button> <button>5</button>
                                                    </div>
                                                </div>
                                                <div class="box4">
                                                    <div class="radioboxrow">
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="stopped_started"
                                                                name="stopped_started"
                                                                value='0'
                                                                onChange={(e) => setpast2(e.target.value)} />

                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="stopped_started"
                                                                name="stopped_started"
                                                                value='1'
                                                                onChange={(e) => setpast2(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="stopped_started"
                                                                name="stopped_started"
                                                                value='2'
                                                                onChange={(e) => setpast2(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="stopped_started"
                                                                name="stopped_started"
                                                                value='3'
                                                                onChange={(e) => setpast2(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="stopped_started"
                                                                name="stopped_started"
                                                                value='4'
                                                                onChange={(e) => setpast2(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="stopped_started"
                                                                name="stopped_started"
                                                                value='5'
                                                                onChange={(e) => setpast2(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="">
                                            <div class="box">
                                                <div class="box1">
                                                    <p>
                                                        Over the past month or so, how often
                                                        <br />
                                                        you had a weak stream of urine?
                                                    </p>
                                                </div>
                                                <div class="box2">
                                                    <figure>
                                                        <img src="assets/images/symptom-severity/4.png" />
                                                    </figure>
                                                </div>
                                                <div class="box3">
                                                    <div class="number">
                                                        <button>0</button> <button>1</button> <button>2</button>
                                                        <button>3</button> <button>4</button> <button>5</button>
                                                    </div>
                                                </div>
                                                <div class="box4">
                                                    <div class="radioboxrow">
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="weak_stream"
                                                                name="weak_stream"
                                                                value='0'
                                                                onChange={(e) => setpast3(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="weak_stream"
                                                                name="weak_stream"
                                                                value='1'
                                                                onChange={(e) => setpast3(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="weak_stream"
                                                                name="weak_stream"
                                                                value='2'
                                                                onChange={(e) => setpast3(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="weak_stream"
                                                                name="weak_stream"
                                                                value='3'
                                                                onChange={(e) => setpast3(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="weak_stream"
                                                                name="weak_stream"
                                                                value='4'
                                                                onChange={(e) => setpast3(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="weak_stream"
                                                                name="weak_stream"
                                                                value='5'
                                                                onChange={(e) => setpast3(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="">
                                            <div class="box">
                                                <div class="box1">
                                                    <p>
                                                        Over the past month or so, how
                                                        <br />
                                                        often have you found it difﬁcult
                                                        <br />
                                                        to postpone or defer urination?
                                                    </p>
                                                </div>
                                                <div class="box2">
                                                    <figure>
                                                        <img src="assets/images/symptom-severity/5.png" />
                                                    </figure>
                                                </div>
                                                <div class="box3">
                                                    <div class="number">
                                                        <button>0</button> <button>1</button> <button>2</button>
                                                        <button>3</button> <button>4</button> <button>5</button>
                                                    </div>
                                                </div>
                                                <div class="box4">
                                                    <div class="radioboxrow">
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="defer_urination"
                                                                name="defer_urination"
                                                                value='0'
                                                                onChange={(e) => setpast4(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="defer_urination"
                                                                name="defer_urination"
                                                                value='1'
                                                                onChange={(e) => setpast4(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="defer_urination"
                                                                name="defer_urination"
                                                                value='2'
                                                                onChange={(e) => setpast4(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="defer_urination"
                                                                name="defer_urination"
                                                                value='3'
                                                                onChange={(e) => setpast4(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="defer_urination"
                                                                name="defer_urination"
                                                                value='4'
                                                                onChange={(e) => setpast4(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="defer_urination"
                                                                name="defer_urination"
                                                                value='5'
                                                                onChange={(e) => setpast4(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="">
                                            <div class="box">
                                                <div class="box1">
                                                    <p>
                                                        Over the past month or so, how
                                                        <br />
                                                        often have you had to push or
                                                        <br />
                                                        strain to start the outﬂow of urine?
                                                    </p>
                                                </div>
                                                <div class="box2">
                                                    <figure>
                                                        <img src="assets/images/symptom-severity/6.png" />
                                                    </figure>
                                                </div>
                                                <div class="box3">
                                                    <div class="number">
                                                        <button>0</button> <button>1</button> <button>2</button>
                                                        <button>3</button> <button>4</button> <button>5</button>
                                                    </div>
                                                </div>
                                                <div class="box4">
                                                    <div class="radioboxrow">
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="push_strain"
                                                                name="push_strain"
                                                                value='0'
                                                                onChange={(e) => setpast5(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="push_strain"
                                                                name="push_strain"
                                                                value='1'
                                                                onChange={(e) => setpast5(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="push_strain"
                                                                name="push_strain"
                                                                value='2'
                                                                onChange={(e) => setpast5(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="push_strain"
                                                                name="push_strain"
                                                                value='3'
                                                                onChange={(e) => setpast5(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="push_strain"
                                                                name="push_strain"
                                                                value='4'
                                                                onChange={(e) => setpast5(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                        <label class="">
                                                            <input
                                                                type="radio"
                                                                formcontrolname="push_strain"
                                                                name="push_strain"
                                                                value='5'
                                                                onChange={(e) => setpast5(e.target.value)}
                                                            />
                                                            <span class="checkmark" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 offset-sm-3">
                                        <div class="">
                                            <div class="box">
                                                <div class="box1">
                                                    <p>
                                                        Over the past 1 month, how many times did
                                                        <br />
                                                        you most typically get up to pass urine from the
                                                        <br />
                                                        time you went to bed at night until the time
                                                        <br />
                                                        you got up in the morning?
                                                    </p>
                                                </div>
                                                <div class="box2">
                                                    <figure>
                                                        <img src="assets/images/symptom-severity/7.png" />
                                                    </figure>
                                                </div>
                                                <div class="box3">
                                                    <div class="number">
                                                        <button>0</button> <button>1</button> <button>2</button>
                                                        <button>3</button> <button>4</button> <button>5</button>
                                                    </div>
                                                </div>
                                                <div class="box4">
                                                    <div class="radioboxrow">
                                                        <div class="labelbox">
                                                            <label class="">
                                                                <input
                                                                    type="radio"
                                                                    formcontrolname="pash_urine"
                                                                    name="pash_urine"
                                                                    value='0'
                                                                    onChange={(e) => setpast6(e.target.value)}
                                                                />
                                                                <span class="checkmark" />
                                                            </label>
                                                            <p>None</p>
                                                        </div>
                                                        <div class="labelbox">
                                                            <label class="">
                                                                <input
                                                                    type="radio"
                                                                    formcontrolname="pash_urine"
                                                                    name="pash_urine"
                                                                    value='1'
                                                                    onChange={(e) => setpast6(e.target.value)}
                                                                />
                                                                <span class="checkmark" />
                                                            </label>
                                                            <p>Once</p>
                                                        </div>
                                                        <div class="labelbox">
                                                            <label class="">
                                                                <input
                                                                    type="radio"
                                                                    formcontrolname="pash_urine"
                                                                    name="pash_urine"
                                                                    value='2'
                                                                    onChange={(e) => setpast6(e.target.value)}
                                                                />
                                                                <span class="checkmark" />
                                                            </label>
                                                            <p>Twice</p>
                                                        </div>
                                                        <div class="labelbox">
                                                            <label class="">
                                                                <input
                                                                    type="radio"
                                                                    formcontrolname="pash_urine"
                                                                    name="pash_urine"
                                                                    value='3'
                                                                    onChange={(e) => setpast6(e.target.value)}
                                                                />
                                                                <span class="checkmark" />
                                                            </label>
                                                            <p>Thrice</p>
                                                        </div>
                                                        <div class="labelbox">
                                                            <label class="">
                                                                <input
                                                                    type="radio"
                                                                    formcontrolname="pash_urine"
                                                                    name="pash_urine"
                                                                    value='4'
                                                                    onChange={(e) => setpast6(e.target.value)}
                                                                />
                                                                <span class="checkmark" />
                                                            </label>
                                                            <p>Four Times</p>
                                                        </div>
                                                        <div class="labelbox">
                                                            <label class="">
                                                                <input
                                                                    type="radio"
                                                                    formcontrolname="pash_urine"
                                                                    name="pash_urine"
                                                                    value='5'
                                                                    onChange={(e) => setpast6(e.target.value)}
                                                                />
                                                                <span class="checkmark" />
                                                            </label>
                                                            <p>5 or more times</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 quality">
                                        <div class="">
                                            <div class="box">
                                                <h1>
                                                    QUALITY OF LIFE ASSESSMENT.<sup>2</sup>
                                                </h1>
                                                <div class="box1">
                                                    <p>
                                                        If you were to spend the rest of your life with your urinary
                                                        condition
                                                        <br />
                                                        remaining just the way it is now, how would you feel about
                                                        it?
                                                    </p>
                                                </div>
                                                {/* <form onSubmit={handalsubmit2}> */}
                                                <div class="box3">
                                                    <div class="number row">
                                                        <button class='qly' data-value='0' data-name="Delighted" style={{ cursor: 'pointer' }}>
                                                            <figure class="icon">
                                                                <img src="assets/images/symptom-severity/quality1.png" />
                                                            </figure>
                                                        </button>
                                                        <button class='qly' data-value='1' data-name="Pleased" style={{ cursor: 'pointer' }}>
                                                            <figure class="icon">
                                                                <img src="assets/images/symptom-severity/quality.png" />
                                                            </figure>
                                                        </button>
                                                        <button class='qly' data-value='2' data-name="Mostly satisfied" style={{ cursor: 'pointer' }}>
                                                            <figure class="icon">
                                                                <img src="assets/images/symptom-severity/quality2.png" />
                                                            </figure>
                                                        </button>
                                                        <button class='qly' data-value='3' data-name="Mixed" style={{ cursor: 'pointer' }}>
                                                            <figure class="icon">
                                                                <img src="assets/images/symptom-severity/quality3.png" />
                                                            </figure>
                                                        </button>
                                                        <button class='qly' data-value='4' data-name="Mostly disatisfied" style={{ cursor: 'pointer' }}>
                                                            <figure class="icon">
                                                                <img src="assets/images/symptom-severity/quality4.png" />
                                                            </figure>
                                                        </button>
                                                        <button class='qly' data-value='5' data-name="Unhappy" style={{ cursor: 'pointer' }}>
                                                            <figure class="icon">
                                                                <img src="assets/images/symptom-severity/quality5.png" />
                                                            </figure>
                                                        </button>
                                                        <button class='qly' data-value='6' data-name="Terrible" style={{ cursor: 'pointer' }}>
                                                            <figure class="icon">
                                                                <img src="assets/images/symptom-severity/quality6.png" />
                                                            </figure>
                                                        </button>
                                                    </div>
                                                </div>
                                                {/* </form> */}
                                                <div class="clearfix" />
                                                <div class="box4" id="qlybox" style={{ display: 'none' }} ngid="qualityOfLiftIndex">
                                                    <div class="col-sm-6 col-sm-offset-3 m-auto">
                                                        <div class="box">
                                                            <div class="box1 col-xs-8">
                                                                <p>Quality of Life Index =</p>
                                                            </div>
                                                            <div class="box1 boxscore col-xs-4">
                                                                <p id="qlyval">

                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="hidden"
                                                        className='form-control'
                                                        id="qlyvalinput"

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix" />

                                        <div class="col-sm-12 mb-3">

                                            <div class="text-center">
                                                <div id="error-message"></div>

                                                <button style={{ color: "white" }} class="border-0 btn btn-lg btn-brand-orange btn-lg m-auto px-5 purplebtn submit qul12">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>

                                        <div
                                            ngif="totalScore >= 0 && qualityInNumber >= 0"
                                            class="col-sm-8 offset-sm-2 totalscore"
                                        >
                                            <div class="" id="totalScore" style={{ display: 'none' }}>
                                                <div class="outerbox">
                                                    <div class="box">
                                                        <div class="box1 col-xs-8">
                                                            <p>Your Total Score =</p>
                                                        </div>
                                                        <div class="box1 boxscore col-xs-4">
                                                            <p>


                                                                {Happy ? Happy : "0"}
                                                                {/* {
                                                                    calcution != "" ?
                                                                        calcution.map((main, index) => (
                                                                            { main }
                                                                        )) : "0"} */}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <h4>
                                                        (Add your scores from these questions and write the total
                                                        score)
                                                    </h4>

                                                    <div class="box2">
                                                        <h3>
                                                            KNOW AND TRACK THE SEVERITY
                                                            <br />
                                                            OF YOUR BPH SYMPTOMS AS PER
                                                            <br />
                                                            YOUR TOTAL BPH SCORE.<sup>3</sup>
                                                        </h3>
                                                    </div>
                                                    <div class="box2 meterbox position-relative">
                                                        <figure class="meter">
                                                            <img
                                                                src="assets/images/meter.png"
                                                                class="img-fluid"
                                                            />
                                                            <figure class="indicator" style={{ transform: "rotate(" + scoreper + "deg)" }}>
                                                                <img
                                                                    src="assets/images/indicator.png"
                                                                    class="img-fluid"
                                                                />
                                                            </figure>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

                <section
                    ngif="totalScore >= 0 && qualityInNumber >= 0"
                    class="howsevere"
                    id="contentToConvert"
                >
                    <div class="container" id="howserver" style={{ display: 'none' }}>
                        <div class="row">
                            <h1>HOW SEVERE ARE YOUR SYMPTOMS?</h1>
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="symptomscore">
                                            <div class="scorenumber">
                                                <h2>
                                                    {Happy ? Happy : "0"}
                                                </h2>
                                            </div>
                                            <div class="scoredetails">
                                                <p>Your BPH symptom score</p>
                                                <h4>
                                                    {Mild2 ? Mild2 :
                                                        Moderate2 ? Moderate2 :
                                                            Severe2 ? Severe2 : ""}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="symptomscore">
                                            <div class="scorenumber">
                                                <h2 >
                                                    <p id="qlyval1"></p>
                                                </h2>
                                            </div>
                                            <div class="scoredetails">
                                                <p>Your Quality of Life Score</p>
                                                <h4 id="qlyvalname">

                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 scoresymptommeaning mt-5">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="row">
                                        <h4>
                                            WHAT YOUR SYMPTOM SCORE MEANS:<sup>1</sup>
                                        </h4>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="row scoresymptommeaningbox">
                                        <div class="col-xs-5 score">
                                            <h4>Score</h4>
                                        </div>
                                        <div class="col-xs-7 severity">
                                            <h4>Symptom Severity</h4>
                                        </div>
                                    </div>
                                    <div class="row scoresymptommeaningbox">
                                        <div class="col-xs-5 score">
                                            <button>
                                                <h4>1-7</h4>
                                            </button>
                                        </div>
                                        <div class="col-xs-7 severity">
                                            <h4>Mild</h4>
                                        </div>
                                    </div>
                                    <div class="row scoresymptommeaningbox">
                                        <div class="col-xs-5 score">
                                            <button>
                                                <h4>8-19</h4>
                                            </button>
                                        </div>
                                        <div class="col-xs-7 severity">
                                            <h4>Moderate</h4>
                                        </div>
                                    </div>
                                    <div class="row scoresymptommeaningbox">
                                        <div class="col-xs-5 score">
                                            <button>
                                                <h4>20-35</h4>
                                            </button>
                                        </div>
                                        <div class="col-xs-7 severity">
                                            <h4>Severe</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 details">
                                    <p>
                                        Your total score is
                                        totalScore
                                        ,<br />
                                        indicative of
                                        <b>

                                            getBPHSymptom

                                        </b>
                                        BPH symptoms.
                                        <br />
                                        Depending on your score, your
                                        <br />
                                        symptoms can be categorised as:
                                        <br />
                                        Mild (1-7), Moderate (8-19) or Severe (20-35).
                                        <br />
                                        <b>
                                            However, it is advisable to visit a doctor
                                            <br />
                                            for a definitive diagnoshis
                                        </b>
                                        <br />
                                        <b>
                                            Visit :
                                            <a href="https://www.whatarelief.in/" target="blank">
                                                https://www.whatarelief.in/
                                            </a>
                                            <br />
                                            to find your nearest one
                                        </b>
                                    </p>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 btn text-left">
                                        <img src="assets/images/symptom-severity/btn1.png" />
                                    </div>
                                    <div class="col-sm-6 btn text-left cursor-auto">
                                        <img src="assets/images/symptom-severity/btn2.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="row">
                                <div class="summary mt-5">
                                    <div>
                                        <h1>
                                            To Check BPH Score on WhatsApp, Give a miss call on
                                            <span class="numberitalic">7877315315</span> or share this
                                            with No. with your loved ones.
                                        </h1>
                                    </div>
                                    <h1>Summary of the score</h1>
                                    <div class="col-sm-12">
                                        <div class="row">
                                            <button
                                                ngfor="let values of questionaiesVal"
                                                class="cursor-auto"
                                            >
                                                {item != 0 ? item.past : "0"}
                                            </button>
                                            <button
                                                ngfor="let values of questionaiesVal"
                                                class="cursor-auto"
                                            >
                                                {item != 0 ? item.past1 : "0"}
                                            </button>
                                            <button
                                                ngfor="let values of questionaiesVal"
                                                class="cursor-auto"
                                            >
                                                {item != 0 ? item.past2 : "0"}
                                            </button>
                                            <button
                                                ngfor="let values of questionaiesVal"
                                                class="cursor-auto"
                                            >
                                                {item != 0 ? item.past3 : "0"}
                                            </button>
                                            <button
                                                ngfor="let values of questionaiesVal"
                                                class="cursor-auto"
                                            >
                                                {item != 0 ? item.past4 : "0"}
                                            </button>
                                            <button
                                                ngfor="let values of questionaiesVal"
                                                class="cursor-auto"
                                            >
                                                {item != 0 ? item.past5 : "0"}
                                            </button>
                                            <button
                                                ngfor="let values of questionaiesVal"
                                                class="cursor-auto"
                                            >
                                                {item != 0 ? item.past6 : "0"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main >
            <Footer />
        </React.Fragment >
    )
}
