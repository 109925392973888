import React, { Component } from "react";
import { render } from "react-dom";

export default class Geolocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    componentDidMount() {
        navigator.geolocation.getCurrentPosition(function (position) {
            sessionStorage.setItem('Latitude', position.coords.latitude);
            sessionStorage.setItem('Longitude', position.coords.longitude);
            // document.getElementById('root').innerHTML = "latitude: " + position.coords.latitude + "<br>longitude: " + position.coords.longitude;
        });
    }

    render() {
        return (
            <div >
            </div>
        );
    }

    // state = {
    //     option: null,
    //     lat: null,
    //     long: null,
    //     speed: null,
    //     positon: null,
    //     message: null
    // };

    // componentDidMount() {
    //     if ("geolocation" in navigator) {
    //         console.log("YES");
    //         this.setState({ option: "yes" });
    //     } else {
    //         console.log("NO");
    //         this.setState({ option: "no" });
    //     }

    //     navigator.geolocation.getCurrentPosition(position => {
    //         this.setState({
    //             lat: position.coords.latitude,
    //             long: position.coords.longitude,
    //             speed: position.coords.speed,
    //             positon: position
    //         });
    //         console.log(position);
    //     }, error => {
    //         this.setState({
    //             message: "Error code = " + error.code + "-" + error.message
    //         })
    //         console.log("Error code = " + error.code + "-" + error.message)
    //     });
    // }

    // render() {
    //     return (
    //         <div>
    //             {this.state.option},
    //             <br />
    //             {this.state.lat},
    //             <br />
    //             {this.state.long},
    //             <br />
    //             {this.state.speed}
    //             <br />
    //             {this.state.message}
    //         </div>
    //     )
    // }

}

