import React from 'react'
import MrHeader from '../../Comman/MRHeader';
import MrSidebar from '../../Comman/MRSidebar';
import api from '../../../Comman_Admin/axios';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from "react"
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiPaper-root": {
            borderRadius: "0px",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0);"
        },
        "& .MuiTableSortLabel-icon ": {
            opacity: 1
        }
    },
}));

export default function MrChemistTable() {

    const [user, setuser] = useState([])

    let history = useHistory();

    useEffect(() => {
        MrChemistlist()
    }, []);

    function MrChemistlist(req, res) {
        try {
            let customToken = sessionStorage.getItem('customToken');
            if (customToken) {
                let headers = {
                    Authorization:
                        "Bearer " + customToken,
                    "Content-Type": "application/json",
                };
                api.get(`/employee/get_chemist`, { headers })
                    .then(res => {
                        const tableData = res.data.data;
                        setuser(tableData)
                    })
            }
        } catch (err) {
            res.status(404).json({ success: false, message: err.message })
        }

    }



    function updateChemist(id) {

        history.push(`/admin/editchemist/${id}`);
    }

    const classes = useStyles();

    const columns = [
        {
            title: 'Chemist Shop Name', field: 'chemist_shop'
        },
        {
            title: 'Contact Number', field: 'contact_no'
        },
        {
            title: 'Address', field: 'address_line1'
        },
        {
            title: 'Address With Google', field: 'address_line2'
        },
    ]

    return (
        <React.Fragment>
            <MrHeader />
            <div className="main-container" id="container">
                <MrSidebar />
                <div id="content" className="main-content">
                    <div className="layout-px-spacing layout-spacing layout-top-spacing center">
                        <div className="row layout-spacing">
                            <div className="col-lg-12">
                                <div className="statbox widget box box-shadow">
                                    <div className="widget-content widget-content-area">
                                        <div className="table-responsive mb-4">


                                            <div className="row">
                                                <div className="col-xl-6 col-md-6 col-sm-6 col-6">
                                                    <h4>Chemist List</h4>
                                                </div>

                                                <div className="col-xl-6 col-md-6 col-sm-6 col-6 text-right my-2">
                                                    <Link to="/mr/addchemist"><button className="btn btn-primary"> Add Chemist </button></Link>
                                                </div>
                                            </div>

                                            <div className={classes.root}>
                                                <MaterialTable title=""
                                                    data={user}
                                                    columns={columns}
                                                    options={{
                                                        sorting: true, search: true,
                                                        searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
                                                        paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
                                                        paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both",
                                                        // actionsColumnIndex: -1,
                                                        draggable: false,
                                                        headerStyle: { color: "black" }
                                                        // headerStyle: { background: "#f44336", color: "#fff" }
                                                    }}
                                                    actions={[
                                                        // {
                                                        //     icon: 'edit',
                                                        //     tooltip: 'Edit User',
                                                        //     onClick: (event, rowData) => updateChemist(rowData._id),

                                                        // },
                                                        // {
                                                        //     icon: 'delete',
                                                        //     tooltip: 'Delete User',
                                                        //     onClick: (event, rowData) => deleteuser(rowData._id)

                                                        // },
                                                        // {
                                                        //     icon: 'add',
                                                        //     tooltip: 'Add User',
                                                        //     isFreeAction: true,
                                                        //     onClick: (event, rowData) => adduser(rowData.form)
                                                        // }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
