import React from 'react'
import { Link } from 'react-router-dom'

export default function MrSidebar() {
    return (
        <React.Fragment>
            <div className="overlay" />
            <div className="search-overlay" />
            <div className="sidebar-wrapper sidebar-theme">
                <nav id="sidebar">
                    <div className="shadow-bottom" />
                    <ul className="list-unstyled menu-categories" id="accordionExample">
                        <li className="menu">
                            <Link
                                to="/mr/dashboard"
                                data-active="true"
                                aria-expanded="true"
                                className="dropdown-toggle"
                            >
                                <div className="" >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-home"
                                    >
                                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                                        <polyline points="9 22 9 12 15 12 15 22" />
                                    </svg>
                                    <span>Dashboard</span>
                                </div>

                            </Link>
                            {/* <ul
                                    className="collapse submenu list-unstyled show"
                                    id="dashboard"
                                    data-parent="#accordionExample"
                                >
                                    <li className="active">
                                        <a href="/admin/dashboard"> Admin-Dashboard </a>
                                    </li>

                                </ul> */}
                        </li>
                        <li className="menu">
                            <Link
                                to="/mr/chemist"
                                // data-toggle="collapse"
                                aria-expanded="false"
                                className="dropdown-toggle"
                            >
                                <div className="" >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-box"
                                    >
                                        <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" />
                                        <polyline points="3.27 6.96 12 12.01 20.73 6.96" />
                                        <line x1={12} y1="22.08" x2={12} y2={12} />
                                    </svg>
                                    <span>Chemist</span>
                                </div>
                            </Link>
                        </li>
                        <li className="menu">
                            <Link
                                to="/mr/specialist"
                                // data-toggle="collapse"
                                aria-expanded="false"
                                className="dropdown-toggle"
                            >
                                <div className="">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-zap"
                                    >
                                        <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2" />
                                    </svg>
                                    <span>Specialist Doctor</span>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </React.Fragment>

    )
}
