import React from 'react'
import AdminHeader from '../Comman/AdminHeader';
import AdminSidebar from '../Comman/AdminSidebar';
import { useHistory, useParams } from 'react-router-dom';
import { useState, useEffect } from "react"
import Swal from 'sweetalert2';
import api from '../../Comman_Admin/axios';


export default function AdminProfile() {

    const [name, setname] = useState('');
    const [old_password, setold_password] = useState('');
    const [new_password, setnew_password] = useState('');
    const [con_newpassword, setcon_newpassword] = useState('');


    const [values, setValues] = useState({
        name: '',
        old_password: '',
        new_password: '',
        con_newpassword: "",
    });

    const validateEmail = (email) => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    const [error, setError] = useState(null)
    const [error1, setError1] = useState(null)


    const errorDiv = error
        ? <div className="text-center alert alert-danger">
            {error}
        </div>
        : '';

    const errorDiv1 = error1
        ? <div className="text-center alert alert-danger">
            {error1}
        </div>
        : '';
    const { id } = useParams();
    let history = useHistory();

    useEffect(() => {
        admindataget()
    }, [])


    function admindataget(req, res) {
        try {
            let customToken = sessionStorage.getItem('customToken');
            if (customToken) {
                let headers = {
                    Authorization:
                        "Bearer " + customToken,
                    "Content-Type": "application/json",
                };
                api.get(`/admin/get_profile`, { headers })
                    .then(res => {
                        const tableData = res.data.data[0];
                        setValues({
                            name: tableData.name[0],
                        })
                        setname(tableData.name);
                    })
            }
        } catch (err) {
            res.status(404).json({ success: false, message: err.message })
        }
    }


    function handleSubmit(event) {
        event.preventDefault()
        setError(null);


        if (name.toString().trim().length < 0 || name.toString().trim().length < 3) {
            setError('Name Must Be at least 3 character Long');
        }

        else {
            let item = {
                name: name,
            }
            let customToken = sessionStorage.getItem('customToken');

            if (customToken) {
                let headers = {
                    Authorization:
                        "Bearer " + customToken,
                    "Content-Type": "application/json",
                };
                api.post(`/admin/edit_profile`, item, { headers }).then((res) => {
                    if (res.data.success === false) {
                        setError(res.data.message)
                    } else {
                        Swal.fire({
                            position: 'top-center',
                            icon: 'success',
                            title: 'Profile Update Successfully',
                            showConfirmButton: false,
                            timer: 1000,
                        })
                        setTimeout(() => {
                            history.push('/admin/dashboard')
                            // window.location.reload(true)
                        }, 1000);
                    }
                }).catch(err => {
                    if (err.response) {
                        setError(err.response.data.message)
                    }
                    else if (err.request) {
                        setError(err.request);
                    }
                    else {
                        setError(err.message);
                    }
                })
            }
        }
    }

    function handleSubmitnew_password(event) {
        event.preventDefault()
        setError1(null);
        // toString().trim().length
        if (old_password.toString().trim().split(' ').join('').length < 0 || old_password.toString().trim().split(' ').join('').length < 6) {
            setError1('Old Password Must Be at least 6 character Long');
        }
        else if (new_password.toString().trim().split(' ').join('').length < 0 || new_password.toString().trim().split(' ').join('').length < 6) {
            setError1('New Password Must Be at least 6 character Long');
        }
        else if (con_newpassword.toString().trim().split(' ').join('').length < 0 || con_newpassword.toString().trim().split(' ').join('').length < 6) {
            setError1('Confirm Password must be at least 6 character long');
        }
        else {
            let items = {
                old_password: old_password,
                new_password: new_password,
                con_newpassword: con_newpassword,
            }
            let customToken = sessionStorage.getItem('customToken');

            if (customToken) {
                let headers = {
                    Authorization:
                        "Bearer " + customToken,
                    "Content-Type": "application/json",
                };
                console.log(items)
                api.post(`/admin/change_password`, items, { headers }).then((res) => {
                    if (res.data.success === false) {
                        setError1(res.data.message);
                    } else {
                        Swal.fire({
                            position: 'top-center',
                            icon: 'success',
                            title: 'New Password Change Successfully',
                            showConfirmButton: false,
                            timer: 1000,
                        })
                        setTimeout(() => {
                            history.push('/admin/dashboard')
                            // window.location.reload(true)
                        }, 1000);
                    }
                }).catch(err => {
                    if (err.response) {
                        setError1(err.response.data.success);
                    }
                    else if (err.request) {
                        setError1(err.request);
                    }
                    else {
                        setError1(err.message);
                    }
                })
            }

        }
    }


    return (
        <React.Fragment>
            <AdminHeader />
            <div className="main-container" id="container">
                <AdminSidebar />
                <div id="content" className="main-content">
                    <div className="container">
                        <div className="layout-px-spacing layout-spacing layout-top-spacing center">
                            <div className="row">
                                <div id="flRegistrationForm" className="col-lg-12 layout-spacing">
                                    <div className="statbox widget box box-shadow">

                                        <div className="widget-content widget-content-area">
                                            <div className="row col-xl-12 col-md-12 col-sm-12 col-12 text-left">
                                                <h4>Admin Profile</h4>
                                            </div>
                                            <hr />
                                            <form onSubmit={handleSubmit} >
                                                <div className="form-group mb-4">
                                                    <label><strong style={{ color: "black" }}>Name</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        placeholder="Enter Name"
                                                        value={name}
                                                        required
                                                        onChange={(e) => setname(e.target.value)}
                                                    />
                                                </div>

                                                <div className='col-md-12'> {errorDiv} </div>

                                                <div className="form-group form-check pl-0 mt-5 text-center">
                                                    <button type="submit" className="btn btn-primary mx-2">
                                                        Submit
                                                    </button>
                                                    <button type="submit" className="btn btn-info mx-2 " onClick={() => history.goBack()}>
                                                        Go Back
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                <div id="flRegistrationForm" className="col-lg-12 layout-spacing my-3">
                                    <div className="statbox widget box box-shadow">

                                        <div className="widget-content widget-content-area">
                                            <div className="ror col-xl-12 col-md-12 col-sm-12 col-12 text-left">
                                                <h4>Admin Password Change</h4>
                                            </div>
                                            <hr />
                                            <form onSubmit={handleSubmitnew_password} >
                                                <div className="form-group mb-4">
                                                    <label><strong style={{ color: "black" }}>Old Password</strong></label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="old_new_password"
                                                        placeholder="Enter Old Password"
                                                        // value={new_password}
                                                        required
                                                        onChange={(e) => setold_password(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mb-4">
                                                    <label><strong style={{ color: "black" }}>New Password</strong></label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="new_password"
                                                        placeholder="Enter New Password"
                                                        // value={new_password}
                                                        required
                                                        onChange={(e) => setnew_password(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group mb-4">
                                                    <label><strong style={{ color: "black" }}>Confirm Password</strong></label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="con_newpassword"
                                                        placeholder="Enter Confirm Password"
                                                        // value={new_password}
                                                        // required
                                                        onChange={(e) => setcon_newpassword(e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-md-12'> {errorDiv1} <hr /> </div>

                                                <div className="form-group form-check pl-0 mt-5 text-center">
                                                    <button type="submit" className="btn btn-primary mx-2">
                                                        Submit
                                                    </button>
                                                    <button type="submit" className="btn btn-info mx-2 " onClick={() => history.goBack()}>
                                                        Go Back
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}
