import React from 'react'
import { useHistory } from 'react-router-dom';
import { useState } from "react"
import AdminHeader from '../../Comman/AdminHeader';
import AdminSidebar from '../../Comman/AdminSidebar';
import api from '../../../Comman_Admin/axios';

export default function AdminAddMR() {

    const [name, setname] = useState('');
    const [phone, setphone] = useState('');
    const [email, setemail] = useState('');
    const [zone, setzone] = useState('');
    const [state, setstate] = useState('');
    const [employeecode, setemployeecode] = useState('');
    const [designation, setdesignation] = useState('');
    const [headquarter, setheadquarter] = useState('');
    const [image, setimage] = useState([]);
    const [password, setpassword] = useState('');
    const [confirmpassword, setconfirmpassword] = useState('');
    const [status, setstatus] = useState('');


    const [error, setError] = useState(null)
    const errorDiv = error
        ? <div className="text-center alert alert-danger">
            {error}
        </div>
        : '';

    const validateEmail = (email) => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };


    const history = useHistory();

    function handleSubmit(event) {
        event.preventDefault();
        setError(null);


        if (name.toString().trim().length < 0 || name.toString().trim().length < 3) {
            setError('Full Name Must Be at least 3 character Long');
        }
        else if (password.toString().trim().split(' ').join('').length < 0 || password.toString().trim().split(' ').join('').length < 6) {
            setError('Password must be at least 6 character long');
        }
        else if (phone.trim().length < 0 || phone.trim().length < 10) {
            setError('Phone Number Must Be at least 10 character Long');
        }
        else if (!validateEmail(email)) {
            setError('Invalid Email');
        }

        else {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('phone', phone);
            formData.append("email", email);
            formData.append('zone', zone);
            formData.append('state', state);
            formData.append('emp_code', employeecode);
            formData.append('designation', designation);
            formData.append('headquater', headquarter);
            formData.append('profile_pic', image[0]);
            formData.append('password', password);

            // for (let [key, value] of formData) {
            //     console.log(`${key}: ${value}`)
            // }

            let customToken = sessionStorage.getItem('customToken');
            if (customToken) {
                let headers = {
                    Authorization:
                        "Bearer " + customToken,
                    "Content-Type": "application/json",
                };
                api.post(`/admin/add_employee`, formData, { headers })
                    .then(res => {
                        if (res.data.success === true) {
                            // history.goBack();
                            history.push('/admin/mr')
                            // window.location.reload(true)
                        }
                    }).catch(err => {
                        if (err.response) {
                            setError(err.response.data.error.message)
                        }
                        else if (err.request) {
                            setError(err.request);
                        }
                        else {
                            setError(err.message);
                        }
                    })
            }
        }

    }


    return (
        <React.Fragment>
            <AdminHeader />
            <div className="main-container" id="container">
                <AdminSidebar />
                <div id="content" className="main-content">
                    <div className="container layout-spacing layout-top-spacing center">
                        <div className="row">
                            <div id="flRegistrationForm" className="col-lg-12 layout-spacing">
                                <div className="statbox widget box box-shadow">
                                    <div className="widget-content widget-content-area">
                                        <div className="row col-xl-12 col-md-12 col-sm-12 col-12 text-left">
                                            <h4><strong>Add MR</strong></h4>
                                        </div>
                                        <hr />
                                        <form onSubmit={handleSubmit}>
                                            <div className='row'>
                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Name</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        placeholder="Enter Name"
                                                        value={name}
                                                        required
                                                        onChange={(e) => setname(e.target.value)}

                                                    />
                                                </div>
                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Employee Code</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="employeecode"
                                                        placeholder="Enter Employee Code"
                                                        value={employeecode}
                                                        required
                                                        onChange={(e) => setemployeecode(e.target.value)}

                                                    />
                                                </div>

                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Email</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="email"
                                                        placeholder="Enter Email"
                                                        value={email}
                                                        required
                                                        onChange={(e) => setemail(e.target.value)}

                                                    />
                                                </div>
                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Password</strong></label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="password"
                                                        placeholder="Enter Password"
                                                        value={password}
                                                        required
                                                        onChange={(e) => setpassword(e.target.value)}

                                                    />
                                                </div>

                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Phone</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control filterme"
                                                        min="10" max="10" maxLength="10"
                                                        id="phone"
                                                        placeholder="Enter Phone Number"
                                                        value={phone}
                                                        required
                                                        onChange={(e) => setphone(e.target.value)}

                                                    />
                                                </div>

                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Upload Profile Image </strong></label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        id="image"
                                                        placeholder="Enter Profile Image"
                                                        // value={file}
                                                        name="file"
                                                        required
                                                        onChange={(e) => setimage(e.target.files)}
                                                    />
                                                </div>
                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Zone</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="zone"
                                                        placeholder="Enter Zone"
                                                        value={zone}
                                                        required
                                                        onChange={(e) => setzone(e.target.value)}

                                                    />
                                                </div>

                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>State</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="state"
                                                        placeholder="Enter State"
                                                        value={state}
                                                        required
                                                        onChange={(e) => setstate(e.target.value)}

                                                    />
                                                </div>

                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Designation</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="designation"
                                                        placeholder="Enter Designation"
                                                        value={designation}
                                                        required
                                                        onChange={(e) => setdesignation(e.target.value)}

                                                    />
                                                </div>
                                                <div className="form-group mb-4 col-md-6">
                                                    <label><strong style={{ color: "black" }}>Headquarter</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="headquarter"
                                                        placeholder="Enter HeadQuarter"
                                                        value={headquarter}
                                                        required
                                                        onChange={(e) => setheadquarter(e.target.value)}
                                                    />
                                                </div>

                                                <div className='col-md-12'> {errorDiv} <hr /> </div>


                                                <div className="form-group form-check pl-0 mt-5 text-center col-md-12">
                                                    <button type='submit' className="btn btn-primary mx-2">
                                                        Submit
                                                    </button>
                                                    <button type="submit" className="btn btn-info mx-2 " onClick={() => history.goBack()}>
                                                        Go Back
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}
